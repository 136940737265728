import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "../../assets/images/logo/logos_123.png";


const phoneNumber = "+91 8484035810";
const address =
  "Sai Krupa Apartment Purna Post. Kaler, Tq. Bhiwandi Dist. Thane 431302";



const Header = () => {
  const [menuToggle, setMenuToggle] = useState(false);
  const [socialToggle, setSocialToggle] = useState(false);
  const [headerFiexd, setHeaderFiexd] = useState(false);

  window.addEventListener("scroll", () => {
    if (window.scrollY > 200) {
      setHeaderFiexd(true);
    } else {
      setHeaderFiexd(false);
    }
  });

  return (
    <header
      className={`header-section ${headerFiexd ? "header-fixed fadeInUp" : ""}`}
    >
      <div className={`header-top ${socialToggle ? "open" : ""}`}>
        <div className="container">
          <div className="header-top-area">
            <ul className="lab-ul left">
              <li>
                <i className="icofont-ui-call"></i> <span>{phoneNumber}</span>
              </li>
              <li>
                <i className="icofont-location-pin"></i> {address}
              </li>
            </ul>
            {/* <ul className="lab-ul social-icons d-flex align-items-center">
                            <li><p>Find us on : </p></li>
                            {socialList.map((val, i) => (
                                <li key={i}><a href={val.siteLink}><i className={val.iconName}></i></a></li>
                            ))}
                        </ul> */}
          </div>
        </div>
      </div>
      <div className="header-bottom">
        <div className="container-xl">
          <div className="header-wrapper d-flex jusify-content-evenly">
            <div className="logo">
              <Link to="/">
                <img src={Logo} alt="logo" />
              </Link>
            </div>
            <div className="menu-area">
              <div className="menu">
                <ul className={`lab-ul ${menuToggle ? "active" : ""}`}>
                  <li>
                    <NavLink to="/">Home</NavLink>
                  </li>
    
                  <li>
                    <NavLink to="/course-single">SBC Exam</NavLink>
                  </li>
                  {/* <li  className="menu-item-has-children">
                  <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">Sbc exam &nbsp;</a>
                   
                    <ul  className="lab-ul dropdown-menu">
                      {/* <li>
                        <NavLink to="/blog">लेख</NavLink>
                      </li> */}
                      {/* <li><NavLink to="/abhiyan-gallery">फोटो</NavLink></li> */}
                      {/* <li><NavLink to="/abhiyan-video">व्हिडिओ</NavLink></li> */}
                      {/* <li><NavLink to="/pramukh-margdarshak">मार्गदर्शक</NavLink></li> */}
                      {/* <li><NavLink to="/mukhai">मुख्य कायदेविषयक सल्लागार समिती</NavLink></li> */}
                      {/* <li><NavLink to="/rajya-simti">Stateसमन्वय समिती</NavLink></li> */}
                    {/* </ul> */}
                  {/* </li> */} 
                  {/* <li className="menu-item-has-children">
                  <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">Co-Ordinator  &nbsp;</a>
                    
                    <ul  className="lab-ul dropdown-menu">
                      <li>
                        <NavLink to="/instructor">State Coordinator</NavLink>
                      </li>
                      <li><NavLink to="/jilha-sanyojak">Dist. Coordinator</NavLink></li>
                      <li><NavLink to="/taluka-sanyojak">Taluka Coordinator</NavLink></li>
                      <li><NavLink to="/gat-sanyojak">गट- VibhagCoordinator</NavLink></li>
                      <li><NavLink to="/sansta-sanyojak">संस्था / संघटना / मंडळ समन्वयक</NavLink></li>
                      <li><NavLink to="/shala-sanyojak">शाळा / क्लासेस / अकॅडमी / महाविघालय / विद्यापिठ</NavLink></li>
                    </ul>
                  </li> */}
                  <li className="menu-item-has-children">
                  <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">Gallery &nbsp;</a>
   
                    <ul  className="lab-ul dropdown-menu">
                      <li>
                        <NavLink to="/gallery">Photo Gallery</NavLink>
                      </li>
                      <li><NavLink to="/video-gallery">Video Gallery</NavLink></li>
                      {/* <li>
                        <a>न्यूज गॅलरी</a>
                      </li> */}
                    </ul>
                  </li>
                  
                  {/* <li>
                    <NavLink to="/madad">Help </NavLink>
                  </li> */}
                  {/* <li>
                    <NavLink to="/donate">Donation </NavLink>
                  </li> */}
                  <li>
                    <NavLink to="/about">About Us</NavLink>
                  </li>
                  <li>
                    <NavLink to="/contact">Contact Us</NavLink>
                  </li>
                </ul>
              </div>

              <Link to="/login" className="login">
                <i className="icofont-user"></i> <span>Log in</span>{" "}
              </Link>
              <Link to="/signup" className="signup">
                <i className="icofont-users"></i> <span>Registeration</span>{" "}
              </Link>

              <div
                className={`header-bar d-lg-none ${menuToggle ? "active" : ""}`}
                onClick={() => setMenuToggle(!menuToggle)}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div
                className="ellepsis-bar d-lg-none"
                onClick={() => setSocialToggle(!socialToggle)}
              >
                <i className="icofont-info-square"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </header>
  );
};

export default Header;
