import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import AchievementTwo from "../component/section/achievement-2";
import Blog from "../component/section/blog";
import Instructor from "../component/section/instructor";
import Skill from "../component/section/skill";
import Student from "../component/section/student";




const subTitle = "Privacy Policy";
const title = "Good Qualification Services And Better Skills";
const desc = "Distinctively provide acces mutfuncto users whereas transparent proceses somes ncentivize eficient functionalities rather than extensible archtectur communicate leveraged services and cross-platform.";

const year = "30+";
const expareance = "Years Of Experiences";



const aboutList = [
    {
        imgUrl: 'assets/images/about/icon/01.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Skilled Instructors',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
    {
        imgUrl: 'assets/images/about/icon/02.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Get Certificate',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
    {
        imgUrl: 'assets/images/about/icon/03.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Online Classes',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
]


const PrivacyPage = () => {
    return (
        <Fragment>
            <Header />
            <PageHeader title={'Privacy policy'} curPage={'Privacy policy'} />
            <div className="about-section style-3 padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        {/* <div className="col">
                            <div className="about-left">
                                <div className="about-thumb">
                                    <img src="assets/images/about/01.jpg" alt="about" />
                                </div>
                                <div className="abs-thumb">
                                    <img src="assets/images/about/02.jpg" alt="about" />
                                </div>
                                <div className="about-left-content">
                                    <h3>{year}</h3>
                                    <p>{expareance}</p>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-8">
                            <div className="about-right">
                                <div className="section-header">
                                    <span className="subtitle">{subTitle}</span>
                                    {/* <h2 className="title">{title}</h2> */}
                                    <p className="privacy-policy-text">
                                        1. SBC EXAM  is committed to the ethical collection, retention and use of information that you provide to us about yourself (“Personal Information”) on this site https://www.sbcexam.com/ (“Site”)
                                    </p>
                                    <p className="privacy-policy-text">
                                        2. Your Personal Information may comprise the following:
                                        <br></br>
                                        a) your name
                                        <br></br>
                                        b) your age
                                        <br></br>
                                        c) your occupation
                                        <br></br>
                                        d) a user ID and password of your choice
                                        <br></br>
                                        e) your email and mailing address
                                        <br></br>
                                        f) your telephone number
                                        <br></br>
                                        g) your payment processing details
                                        <br></br>
                                        h) limited personal details
                                        <br></br>
                                        i) Any other data as Uday Foundation may require
                                    </p>
                                    <p className="privacy-policy-text">
                                        3. The following Privacy Policy sets forth our understanding with you on the collection, use and protection of your Personal Information. Please read the entire Privacy Policy.
                                    </p>
                                    <p className="privacy-policy-text">
                                        4. YOUR USE OF THE WEBSITE CONSTITUTES YOUR CONSENT TO THE ALL THE TERMS AND CONDITIONS CONTAINED IN THIS PRIVACY POLICY (AS AMENDED FROM TIME TO TIME) AND YOU SHALL BE BOUND BY THE SAME.
                                    </p>
                                    <p className="privacy-policy-text">
                                        5. Collection of Information
                                        <br></br>
                                        <br></br>
                                        5.1: Site Browsing: You browse the Site anonymously. We do not require you to identify yourself or reveal any Personal Information while browsing through the Site. However, you may not be able to access certain sections of the Site or interact with us without supplying us with Personal Information. For instance, you would not be able to transact at the Site or make any donations at the Site, without providing the Site with Personal Information. If you desire to register yourself at the Site, you would be required to provide your Personal Information.
                                        <br></br>
                                        <br></br>
                                        5.2: While you are browsing through the Site, the Site’s operating system may automatically record some general information (“General Information”) about your visit such as:
                                        <br></br>
                                        (i) the date and time you visit the Site, along with the address of the previous website your were visiting, if you linked to us from another website
                                        <br></br>
                                        (ii) the type of browser you are using (such as Internet Explorer version ‘X’)
                                        <br></br>
                                        (iii) which ‘hit’ it is on the Site
                                        <br></br>
                                        <br></br>
                                        5.3: The General Information is not Personal Information. SBC EXAM ’s tracking system does not record personal information about individuals or link this information to any Personal Information collected from you.
                                        <br></br>
                                        <br></br>
                                        5.4: The General Information is used by Uday Foundation for statistical analysis, for tracking overall traffic patterns on the Site and to gauge the public interest in SBC EXAM  and the Site. Such General Information may be shared by Uday Foundation with any person, at SBC EXAM ’s discretion.
                                        <br></br>
                                        <br></br>
                                        5.5: Cookies:
                                        <br></br>
                                        “Cookies” are small amounts of data that a website can send to a web browser on a visitor’s computer. The cookie is stored on a visitor’s computer. A cookie may enable the site holder to track how a visitor navigates through its site and the areas in which they show interest. This is similar to a traffic report: it tracks trends and behaviors, but does not identify individuals. Information gathered may include date and time of visits, pages viewed, time spent at the site, and the site visited just before and just after the SBC EXAM  site.
                                    </p>
                                    <p className="privacy-policy-text">
                                        Types of Cookies
                                        <br></br>
                                        Cookies can be set to expire: on a specified date after a specific period of time when a transaction has been completed or when a user turns off his/her browser.
                                        A cookie that is erased from memory when a visitor’s browser closes is called a “session” cookie. Cookies that expire based on a time set by the Web server are called “persistent” cookies.
                                        Persistent cookies may be used in some cases, for example, to recognize when a visitor returns to a website. The web browser will send the cookie information from the initial visit back to the website. This is useful to the visitor if he or she has established a password at a particular website and wants to be identified by that site to perform a function or transaction.
                                        To browse anonymously, you may set your browser to disable cookies or delete cookies. Please note that disabling cookies for some services will impact ability to use that service.
                                        <br></br>
                                        Refusing Cookies on SBC EXAM ’s Website Customers may choose not to accept cookies. Your web browser may alert and permit you to refuse cookies. When you receive an alert, you may choose at that time to refuse that cookie. If the use of cookies is a concern to you, then please make sure your browser has this capability, and that you set your browser to alert you accordingly.
                                        Newer browser versions allow you to be alerted or to automatically refuse cookies. You may need to download a more current version of your web browser from your service provider in order to obtain this option.
                                        <br></br>
                                        <br></br>
                                        Note: Some product features and services provided by SBC EXAM  require you to accept a cookie in order to be able to use the particular functionality or service. These cookies are used to establish a link between the user and the application server. The cookies contain unique session IDs, and no customer data is stored on the cookies.
                                    </p>
                                    <p className="privacy-policy-text">
                                        6.0 Usage of Information
                                        <br></br>
                                        <br></br>
                                        6.1: Personal information will be used by SBC EXAM  for internal purposes including the following:
                                        <br></br>
                                        (i) sending you inter alia emails, features, promotional material, surveys, brochures, catalogues, SBC EXAM  Annual Report, reminders for donations, regular updates on the utilisation of donations by SBC EXAM  and other updates.
                                        <br></br>
                                        (ii) processing your donations to SBC EXAM  and purchases of SBC EXAM  products on the Site. (iii) delivering the SBC EXAM  products you have purchased on the Site /Receipt for donations made by you to SBC EXAM .
                                        <br></br>
                                        (iv) maintaining an internal confidential database of all the Personal Information collected from visitors to the Site
                                        <br></br>
                                        (v) evaluating and administering the Site and SBC EXAM ’s activities, responding to any problems that may arise and gauging visitor trends on the Site.
                                    </p>
                                    <p className="privacy-policy-text">
                                        7. Disclosure of Personal Information by Uday Foundation
                                        <br></br>
                                        <br></br>
                                        7.1: Within SBC EXAM , access to Personal Information collected by SBC EXAM  will be given only to those persons who are authorised by SBC EXAM  and third parties hired by SBC EXAM  to perform administrative services. SBC EXAM  will provide access to third parties for inter alia entering and managing Personal Information in SBC EXAM ’s Database, processing your orders or donations preparing address labels, sending emails, which require such third parties to have access to your Personal Information. Uday Foundation cannot guarantee that such parties will keep your Personal Information confidential and SBC EXAM  will not be liable in any manner for any loss of confidentiality attributable to such third parties.
                                        <br></br>
                                        <br></br>
                                        7.2: SBC EXAM  may share Personal Information with any of persons who are associated with SBC EXAM , including companies and non-governmental organisations affiliated with SBC EXAM  in any manner. SBC EXAM  will retain ownership rights over such information and will share only such portions of the Personal Information as it deems fit.
                                        <br></br>
                                        <br></br>
                                        7.3: SBC EXAM  is not liable in any manner whatsoever for the loss, damage(whether direct, indirect, consequential or incidental) or harm caused to you by the misuse of your Personal Information by a third party who is not an employee of Uday Foundation.
                                        <br></br>
                                        <br></br>
                                        7.4: Notwithstanding anything contained herein or any other contract between you and Uday Foundation, SBC EXAM  reserves the right to disclose any Personal Information about you without notice or consent as needed to satisfy any requirement of law, regulation, legal request or legal investigation, to conduct investigations of breaches of law, to protect the Site, to protect SBC EXAM  and it’s property, to fufill your requests, to protect our visitors and other persons and if required by the policy of SBC EXAM .
                                    </p>
                                    <p className="privacy-policy-text">
                                        8. Security
                                        <br></br>
                                        <br></br>
                                        8.1: SBC EXAM  endevours to use up-to-date security measures to protect your Personal Information.
                                        <br></br>
                                        <br></br>
                                        8.2: SBC EXAM  however does not make any express or implied warranty with respect to the security measures that it may employ from time to time for the protection of the Personal Information.
                                    </p>
                                    <p className="privacy-policy-text">
                                        9. Links to other Websites
                                        <br></br>
                                        <br></br>
                                        The Site contains links to other websites for the benefit of it’s visitors. This Privacy Policy does not apply to such other websites. SBC EXAM  is not expressly or impliedly responsible for, or liable to any loss or damage caused to you by the collection, use and retention of Personal Information by such website in any manner whatsoever. It is important that you review the privacy policies of all websites you visit before you disclose any information to such websites.
                                    </p>
                                    <p className="privacy-policy-text">
                                        10. Variation of the Privacy Policy
                                        <br></br>
                                        <br></br>
                                        SBC EXAM  shall be absolutely entitled at its sole discretion from time to time add to, alter, delete or modify any of the terms and conditions contained herein. Such changes, additions, alterations, deletions or modifications shall be binding on you once you visit the Site after the Privacy Policy has been so amended.
                                    </p>
                                    <p className="privacy-policy-text">
                                        11. Copyright Protection
                                        <br></br>
                                        <br></br>
                                        All content on this Site including graphics, text, icons, interfaces, audio clips, logos, images and software is the property of Uday Foundation and/or its content suppliers and is protected by Indian and international copyright laws. The arrangement and compilation (meaning the collection, arrangement, and assembly) of all content on this Site is the exclusive property of SBC EXAM  and protected by Indian and international copyright laws. Permission is given to use the resources of this Site only for the purposes of making enquiries, making a donation or placing an order for the purchase of SBC EXAM  products. Any other use, including the reproduction, modification, distribution, transmission, republication, display or performance, of the content on this Site can only be made with the express permission of SBC EXAM . All other trademarks, brands and copyrights other than those belonging to SBC EXAM  belong to their respective owners and are their property.
                                    </p>
                                    <p className="privacy-policy-text">
                                        12. SBC EXAM  Donation Refund Policy
                                        SBC EXAM  takes the utmost care to process donations as per the instructions given by our donors, online and offline. However, in case of an unlikely event of an erroneous donation or if the donor would like to cancel his donation, SBC EXAM  will respond to the donor within 7 working days of receiving a valid request for refund from the donor. The timely refund of the donation will depend upon the type of credit card/banking instrument used during the transaction. The donor will have to send SBC EXAM  a written request for a refund within 2 days of making the donation to SBC EXAM ’s official address or help@sbcexam.com along with-
                                        <br></br>
                                        1. Proof of the deduction of the donation amount.
                                        <br></br>
                                        2. In cases where the donation receipt has already been issued to the donor, the donor will have to return the original receipt to us at our office addresses.
                                        <br></br>
                                        3. If the tax exemption certificate is already issued, then we are sorry but we will not be able to refund the donation. However, in case of a valid refund request due to any error on SBC EXAM s part, SBC EXAM  will refund the donation and bear the costs of the same.
                                    </p>

                                </div>
                                {/* <div className="section-wrapper">
                                    <ul className="lab-ul">
                                        {aboutList.map((val, i) => (
                                            <li key={i}>
                                                <div className="sr-left">
                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                </div>
                                                <div className="sr-right">
                                                    <h5>{val.title}</h5>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Student />
            <Instructor />
            <Skill />
            <AchievementTwo />
            <Blog /> */}
            <Footer />
        </Fragment>
    );
}

export default PrivacyPage;
