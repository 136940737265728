import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeaderTwo from "../component/layout/pageheader-2";
import Author from "../component/sidebar/author";



const CourseSingle = () => {
    return (
        <Fragment>
            <Header />
            <PageHeaderTwo />
            <div className="course-single-section padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div className="course-content">
                                            <h3>STUDENT BANEGA CROREPATI </h3>
                                            
                                            <h4 style={{paddingTop: "5px"}}>About exam </h4>
                                            <ul className="lab-ul" style={{fontSize:"20px"}}>
                                            Since our India is based on students, the Phule Shahu Charitable Welfare Foundation has launched a project to foster the intellectual development of its students. The aim is to enhance their intellectual abilities, provide them with knowledge of good things, help them choose to avoid bad things, and encourage them to work towards their own sustenance and talent. The project is called SBC Exam (Student Banega Crorepati).

The first prize of this program will be distributed on January 1, 2025.

First Prize: 1 Crore
Second Prize: 75 Lakhs
Third Prize: 50 Lakhs
Fourth Prize: 25 Lakhs
Fifth Prize: 10 Lakhs
Sixth Prize: 5 Lakhs
Seventh Prize: 3 Lakhs
Eighth Prize: 1 Lakh
Ninth Prize: 75 Thousand
Tenth Prize: 50 Thousand
Eleventh Prize: 25 Thousand
Twelfth Prize: 15 Thousand
Thirteenth Prize: 10 Thousand
Fifteenth Prize: 5 Thousand
Sixteenth Prize: 3 Thousand
Seventeenth Prize: 2 Thousand
Eighteenth Prize: 1 Thousand
Nineteenth Prize: 500 Rupees
Twentieth Prize: 300 Rupees
Students who participate will receive a trophy and certificate. A donation of one hundred rupees is required to join the exam. To register for the exam, visit sbcexam.com or use the SBC Exam mobile application. Please inform all your friends and relatives to join the SBC Exam and make the most of their future.

                                            </ul>
                                        </div>
                                    </div>
                                </div>

 
                                <Author />
                                {/* <Comment /> */}
                                {/* <Respond /> */}
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="sidebar-part">
                                {/* <CourseSideDetail />
                                <CourseSideCetagory /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default CourseSingle;