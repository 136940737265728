import { Fragment, useState } from "react";

import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
// import Register from "../component/section/register";
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { RajyaSanyojakForm } from "../component/section/RajyaSanyojakForm";
// import { GroupForm } from "../component/section/GroupForm";
import { GroupA } from "../component/section/GroupA";
// import { GroupC } from "../component/section/GroupC";
// import { GroupD } from "../component/section/GroupD";
// import { GroupF } from "../component/section/GroupF";
// import { GroupM } from "../component/section/GroupM";
// import { GroupN } from "../component/section/GroupN";
import { ShalaForm } from "../component/section/ShalaForm";
import { JilhaForm } from "../component/section/JilhaForm";
import { TalukaForm } from "../component/section/TalukaForm";
import { GatForm } from "../component/section/GatForm";
import { SanstForm } from "../component/section/SanstaForm";
import Shabhasad from "./Shabhasad";





const groups = [
  { name: "Group A", value: "A", subHeading: "5th To 7th Student"  },
  { name: "Group B", value: "B", subHeading: "8th To 10th Student" },
  { name: "Group C", value: "C", subHeading: "11th To 12th Student"  },
  {
    name: "Group D",
    value: "D",
    subHeading: "Farmer / Employer, Housewife / Retired Person / Businessman / Army / police",
    sec: ["शेतकरी / कामगार", "महिला - गृहिणी", "बचतगट", "अंगणवाडी सेविका", "सेवानिवृत्त कर्मचारी", "उद्योग - व्यवसाय - व्यापार", "सैनिक ( आर्मी )"]
  },
  {
    name: "Group E",
    value: "E",
    subHeading: "Agriculture / Nursing / ITI / Medical / Engineering / etc",
    sec: []
  },
  // {
  //   name: "Group F",
  //   value: "F",
  //   subHeading: "पदवी, पदव्युत्तर विद्यार्थी एम पि एस सी , यू पी एस सी / स्पर्धा परिक्षा विद्यार्थी",
  //   sec: []
  // },
  // {
  //   name: "Group G",
  //   value: "G",
  //   subHeading: "प्राथमिक ' माध्यमिक , उच्च माध्यमिक शिक्षक डि टि एड, बि एड, बि पि एड,एम एड, एम पी एड विद्यार्थी",
  //   sec: []
  // },
  // {
  //   name: "Group H",
  //   value: "H",
  //   subHeading: "कनिष्ठ, वरिष्ठ महाविदयालय प्राध्यापक, अधिव्याख्याता",
  //   sec: []
  // },
  // {
  //   name: "Group I",
  //   value: "I",
  //   subHeading: "वकिल महासंघ, डॉक्टर असोशिएन, विधी विद्यार्थी",
  //   sec: []
  // },
  // {
  //   name: "Group J",
  //   value: "J",
  //   subHeading: "एस टी महामंडळ , एम इ सी बी , कृषि उत्पन्न बाजार समीती कर्मचारी, कला, सांस्कृतिक, क्रिडा विभाग",
  //   sec: []
  // },
  // {
  //   name: "Group K",
  //   value: "K",
  //   subHeading: "आरोग्य, शिक्षण, कृषि कर्मचारी एल आय सी, बँकिंग कर्मचारी",
  //   sec: []
  // },
  // {
  //   name: "Group L",
  //   value: "L",
  //   subHeading: "चतुर्थ श्रेणी कर्मचारी विभाग, पोलीस कर्मचारी Vibhag, कनिष्ठ व वरिष्ठ लिपिक विभाग, रेल्वे / डाक कर्मचारी विभाग",
  //   sec: []
  // },
  // {
  //   name: "Group M",
  //   value: "M",
  //   subHeading: " विधानसभा लोकसभा मंत्रिमंडळ व मंत्रालयातील कर्मचारी Vibhag, महानगर पालिका नगरपालिका सदस्य विभाग, Dist. परिषद पंचायत समिती/ ग्रामपंचायत सदस्य विभाग",
  //   sec: []
  // },
  // {
  //   name: "Group N",
  //   value: "N",
  //   subHeading: "विधानसभा, लोकसभा, मंत्री मंडळ, महानगर पालिका, नगरपालिला, Dist. परिषद, पंचायत समिती, ग्रामपंचायत व इतर सभागृहाचे सदस्य",
  //   sec: []
  // },
  // { name: "Group O", value: "O", subHeading: "खुला Gut सर्वांसाठी", sec: [] },
];




const instructorOptions = [
  { name: "State Coordinator", value: "division", subHeading: "", category:"rajye-sanyojak" },
  { name: "Dist. Coordinator", value: "state", subHeading: "", category:"jilha-sanyojak" },
  { name: "Taluka Coordinator", value: "district", subHeading: "", category:"taluka-sanyojak"  },
  { name: "Gut Coordinator", value: "taluka", subHeading: "", category:"gat-sanyojak"  },
  {


    name: "School / College / Classes / Academy",
    value: "block",
    subHeading: "sanstha-sanyojak",
    category:"sanstha-sanyojak" 
  },
  { name: "Sanstha / Mandal / sanghtana", value: "sansta", subHeading: "" , category:"shala-sanyojak" },
];

const SignupPage = () => {
  const [studentModalOpen, setStudentModalOpen] = useState(false);
  const [instructorModalOpen, setInstructorModalOpen] = useState(false);
  const [shabhasadForm, setShabhsadForm]=useState(false);
  const [group, setGroup] = useState({ name: "", value: "" });

  const handleGroupChange = (group) => {
    setGroup(group);
    setStudentModalOpen(false);
    setInstructorModalOpen(false);
    setShabhsadForm(false);
  };
  const handleClick = () => {
    setShabhsadForm(true);
    setStudentModalOpen(false);
    setInstructorModalOpen(false);
    setGroup(false);
  };
  return (
    <Fragment>
      <Header />
      <PageHeader title={"Registration"} curPage={"Registration"} />
      <div className="login-section padding-tb section-bg">
        <div className="container-fluid">
          <div className="row d-flex gy-4 justify-content-center justify-content-lg-between">
            <div className=" col-12 col-lg-6 col-md-8 col-sm-10 px-5">
              <h2 className="text-center referral-benefits-title">
                Refer Benefits
              </h2>
              <p className="about-us-description mt-5">
              Registering for an exam portal can offer several benefits, depending on the specific features and functionalities of the portal. Here are some common advantages:

Centralized Access: You can manage all your exam-related activities in one place, including registration, scheduling, and results.

Convenience: Online registration is typically faster and more convenient than traditional methods. You can often register at any time from anywhere, without needing to visit a physical location.

Real-Time Updates: Exam portals often provide real-time updates about your registration status, exam schedules, and any changes to the exam details.

Access to Resources: Many portals offer access to study materials, practice tests, and other resources that can help you prepare effectively for your exams.

Efficient Communication: You can receive important notifications, updates, and instructions directly through the portal, ensuring you’re always informed.

Payment Processing: The portal usually facilitates secure online payment options for registration fees and other expenses, making transactions easier and more transparent.

Record Keeping: Your exam history and results are often stored in the portal, providing you with easy access to your performance records and achievements.

Support Services: Many portals offer customer support or help desks to assist with any issues or questions you might have about the registration process or the exam itself.

Personalization: The portal may allow you to customize your exam schedule, set preferences, and manage your profile, offering a more personalized experience.

Efficiency: Automated processes in the portal can streamline registration, reduce errors, and speed up administrative tasks.
              </p>
            </div>
            <div className=" col-12 col-lg-6 col-md-8 col-sm-10 sign-up-modal-parent">
              <div className="container d-flex justify-content-center align-items-center">
                <button
                  onClick={() => {
                    setStudentModalOpen(true);
                  }}
                  className="lab-btn text-white  me-md-5"
                >
                  Student Registration
                </button>
                {/* <button
                
                  className="lab-btn text-white ms-1 me-2 ms-md-5 "
                  onClick={handleClick}
                >
                  सभासद Registration
                </button> */}
                <button
                  onClick={() => {
                    setInstructorModalOpen(true);
                  }}
                  className="lab-btn text-white ms-0 ms-md-5 "
                >
                  Coordinator Registration
                </button>
              </div>
              
              <Modal
                show={studentModalOpen}
                onHide={() => setStudentModalOpen(false)}
                size="xs"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                className="custom-modal"
              >
                <Modal.Header
                  style={{
                    color: "#f16126",
                    display: "flex",
                    justifyContent: "center",
                    borderBottom: "none",
                  }}
                >
                  <Modal.Title
                    className="modal-title"
                    id="contained-modal-title-vcenter"
                  >
                    Student Registration
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "10px",
                  }}
                >
                  {groups.map((item) => {
                    return (
                      <button
                        onClick={() => {
                          handleGroupChange(item);
                        }}
                        className="bg-light text-danger text-left"
                      >
                        <span>{item.name}</span> <span style={{color: "#2664f1"}}> - {item.subHeading}</span>
                      </button>
                    );
                  })}
                </Modal.Body>
              </Modal>
              <Modal
                show={instructorModalOpen}
                onHide={() => setInstructorModalOpen(false)}
                size="xs"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                className="custom-modal"
              >
                <Modal.Header
                  style={{
                    color: "#f16126",
                    display: "flex",
                    justifyContent: "center",
                    borderBottom: "none",
                  }}
                >
                  <Modal.Title id="contained-modal-title-vcenter">
                    Coordinator Registration
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "10px",
                  }}
                >
                  {instructorOptions.map((item) => {
                    return (
                      <button
                        onClick={() => {
                          handleGroupChange(item);
                        }}
                        className="bg-light text-danger"
                      >
                        {item.name}
                      </button>
                    );
                  })}
                </Modal.Body>
              </Modal>
              {group.value === "division" && (
                <div className="mt-5">
                  <RajyaSanyojakForm
                    amount={1000}
                    hideReferral={true}
                    title={group.name}
                    category={group.category}
                  />
                </div>
              )}
              {group.value === "state" && (
                <div className="mt-5">
                  <JilhaForm
                    amount={500}
                    hideReferral={false}
                    title={group.name}
                    category={group.category}
                  />
                </div>
              )}
              {group.value === "district" && (
                <div className="mt-5">
                  <TalukaForm
                    amount={500}
                    title={group.name}
                    subheading={group.subHeading}
                    category={group.category}
                  />
                </div>
              )}
              {group.value === "taluka" && (
                <div className="mt-5">
                  <GatForm
                    amount={500}
                    title={group.name}
                    subheading={group.subHeading}
                    category={group.category}
                  />
                </div>
              )}
              {group.value === "block" && (
                <div className="mt-5">
                  <ShalaForm
                    amount={500}
                    title={group.name}
                    subheading={group.subHeading}
                    category={group.category}
                  />
                </div>
              )}
              {group.value === "sansta" && (
                <div className="mt-5">
                  <SanstForm
                    amount={500}
                    title={group.name}
                    subheading={group.subHeading}
                    category={group.category}
                  />
                </div>
              )}
              {group.value === "A" && (
                <GroupA
                  amount={200}
                  group={group.name}
                  subheading={group.subHeading}
                  groupvalue={group.value}
                />
              )}
              {group.value === "B" && (
                <GroupA
                  amount={200}
                  group={group.name}
                  subheading={group.subHeading}
                  groupvalue={group.value}
                />
              )}
              {group.value === "C" && (
                <GroupA
                  amount={200}
                  group={group.name}
                  subheading={group.subHeading}
                  groupvalue={group.value}
                />
              )}
              {group.value === "D" && (
                <GroupA
                  amount={200}
                  group={group.name}
                  subheading={group.subHeading}
                  groupvalue={group.value}
                />
              )}
              {group.value === "E" && (
                <GroupA
                  amount={200}
                  group={group.name}
                  subheading={group.subHeading}
                  groupvalue={group.value}
                />
              )}
              {group.value === "F" && (
                <GroupA
                  amount={400}
                  group={group.name}
                  subheading={group.subHeading}
                  groupvalue={group.value}
                />
              )}
              {group.value === "G" && (
                <GroupA
                  amount={400}
                  group={group.name}
                  subheading={group.subHeading}
                  groupvalue={group.value}
                />
              )}
              {group.value === "H" && (
                <GroupA
                  amount={400}
                  group={group.name}
                  subheading={group.subHeading}
                  groupvalue={group.value}
                />
              )}
              {group.value === "I" && (
                <GroupA
                  amount={400}
                  group={group.name}
                  subheading={group.subHeading}
                  groupvalue={group.value}
                />
              )}
              {group.value === "J" && (
                <GroupA
                  amount={400}
                  group={group.name}
                  subheading={group.subHeading}
                  groupvalue={group.value}
                />
              )}
              {group.value === "K" && (
                <GroupA
                  amount={400}
                  group={group.name}
                  subheading={group.subHeading}

                  groupvalue={group.value}
                />
              )}
              {group.value === "L" && (
                <GroupA
                  amount={400}
                  group={group.name}
                  subheading={group.subHeading}
                  groupvalue={group.value}
                />
              )}
              {group.value === "M" && (
                <GroupA
                  amount={400}
                  group={group.name}
                  subheading={group.subHeading}
                  groupvalue={group.value}
                />
              )}
              {group.value === "N" && (
                <GroupA
                  amount={400}
                  group={group.name}
                  subheading={group.subHeading}
                  groupvalue={group.value}
                />
              )}
              {group.value === "O" && (
                <GroupA
                  amount={400}
                  group={group.name}
                  subheading={group.subHeading}
                  groupvalue={group.value}
                />
              )}
{shabhasadForm && <Shabhasad amount={100}  title="सभासद Registration" />}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default SignupPage;
