import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import AchievementTwo from "../component/section/achievement-2";
import Blog from "../component/section/blog";
import Instructor from "../component/section/instructor";
import Skill from "../component/section/skill";
import Student from "../component/section/student";

const subTitle = "मुख्य कायदेविषयक सल्लागार समिती";

const names = [
    "मा .अँड.लक्ष्मण पाटील प्रधान (Dist. सरचिटणीस एन .सी .पी छ. संभाजीनगर )",
    "मा.ऍड डि. व्हि .खिल्लारे (संविधान रक्षक समिती तथा चीफ कमांडर समता सैनिक दल )",
    "मा. ऍड शरद भदाणे (मा. सदस्य मराठवाडा धर्मदाय वकील संघ )",
    "मा .ऍड विष्णू ढोबळे (हायकोर्ट )",
    "मा .ऍड राहुल निसर्गंध (हायकोर्ट )",
    "मा. अँड सुभाष नाडे ( हायकोर्ट )",
    "मा.अँड. राहुल जडे (हायकोर्ट )",
    "मा.अँड.विकास पंचांगे (Dist. सत्र न्यायालय )",
    "मा. अँड सतीश इंगळे ( Dist. सत्र न्यायालय )",
]


const aboutList = [
    {
        imgUrl: 'assets/images/about/icon/01.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Skilled Instructors',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
    {
        imgUrl: 'assets/images/about/icon/02.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Get Certificate',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
    {
        imgUrl: 'assets/images/about/icon/03.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Online Classes',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
]


const Mukhai = () => {
    return (
        <Fragment>
            <Header />
            <PageHeader title={'मुख्य कायदेविषयक सल्लागार समिती'} curPage={'मुख्य कायदेविषयक सल्लागार समिती'} />
            <div className="about-section style-3 padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-12 col-sm-8">
                            <div className="about-right">
                                <div className="section-header">
                                    <span className="subtitle">{subTitle}</span>
                                    {/* <h2 className="title">{title}</h2> */}
                                    {names.map((name, index)=>{
                                        return (
                                            <p className="privacy-policy-text" key={index}>
                                        {name}    
                                    </p>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default Mukhai;
