import { Component, Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import About from "../component/section/about";
import Achievement from "../component/section/achievement";
import Banner from "../component/section/banner";
import Blog from "../component/section/blog";
import Category from "../component/section/category";
import Course from "../component/section/course";
import Instructor from "../component/section/instructor";
import Sponsor from "../component/section/sponsor";
import Student from "../component/section/student";
import PageHeader from "../component/layout/pageheader";
import Course2 from "../component/section/course-2"

const courseList = [
    {
        imgUrl: 'assets/images/abhiyan-gallery/1.jpeg',
    },
    {
        imgUrl: 'assets/images/abhiyan-gallery/2.jpeg',
    },
    {
        imgUrl: 'assets/images/abhiyan-gallery/3.jpeg',
    },
    {
        imgUrl: 'assets/images/abhiyan-gallery/4.jpeg',
    },
    {
        imgUrl: 'assets/images/abhiyan-gallery/5.jpeg',
    },
    {
        imgUrl: 'assets/images/abhiyan-gallery/6.jpeg',
    },
    {
        imgUrl: 'assets/images/abhiyan-gallery/7.jpeg',
    },
    {
        imgUrl: 'assets/images/abhiyan-gallery/8.jpeg',
    },
    {
        imgUrl: 'assets/images/abhiyan-gallery/9.jpeg',
    },
    {
        imgUrl: 'assets/images/abhiyan-gallery/10.jpeg',
    },
    {
        imgUrl: 'assets/images/abhiyan-gallery/11.jpeg',
    },
    {
        imgUrl: 'assets/images/abhiyan-gallery/12.jpeg',
    },
    {
        imgUrl: 'assets/images/abhiyan-gallery/13.jpeg',
    },
    {
        imgUrl: 'assets/images/abhiyan-gallery/14.jpeg',
    },
    {
        imgUrl: 'assets/images/abhiyan-gallery/15.jpeg',
    },
    {
        imgUrl: 'assets/images/abhiyan-gallery/16.jpeg',
    },
    {
        imgUrl: 'assets/images/abhiyan-gallery/17.jpeg',
    },
    {
        imgUrl: 'assets/images/abhiyan-gallery/18.jpeg',
    },
   
]


const AbhiyanGallery = () => {
    return (
        <Fragment>
            <Header />
            <PageHeader title={'फोटो'} curPage={'फोटो'} />
            <Course2 images={courseList}/>
            <Footer />
        </Fragment>
    );
}
 
export default AbhiyanGallery;