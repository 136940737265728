


const subTitle = "STUDENT BANEGA CROREPATI ";
const title = "More Info :  +91 8484035810 ";
const subHeading = "The first prize of this program will be distributed on January 1, 2025.";
const desc1 = "First Prize: 1 Crore";
const desc2 = " Second Prize: 75 Lakhs";
const desc3 = "Third Prize: 50 Lakhs";
const desc4 = "Fourth Prize: 25 Lakhs";
const desc5 = "Fifth Prize: 10 Lakhs";
const desc6 = "Sixth Prize: 5 Lakhs";
const desc7 = "Seventh Prize: 3 Lakhs";
const desc8 = "Eighth Prize: 1 Lakh";
const desc9 = "Ninth Prize: 75 Thousand";
const desc10 = "Tenth Prize: 50 Thousand";
const desc11 = "Eleventh Prize: 25 Thousand";
const desc12 = "Twelfth Prize: 15 Thousand";
const desc13 = "Thirteenth Prize: 10 Thousand";
const desc14 = "Fourteen Prize: 10 Thousand";
const desc15 = "Fifteenth Prize: 5 Thousand";
const desc16 = "Sixteenth Prize: 3 Thousand";
const desc17 = "Eighteenth Prize: 1 Thousand";
const desc18 = "Nineteenth Prize: 500 Rupees";
const desc19 = "Twentieth Prize: 300 Rupees";

const aboutList = [
    {
        imgUrl: 'assets/images/about/icon/01.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Skilled Instructors',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
    {
        imgUrl: 'assets/images/about/icon/02.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Get Certificate',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
    {
        imgUrl: 'assets/images/about/icon/03.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Online Classes',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
]


const About = () => {
    return (
        <div className="about-section">
            <div className="container">
                <div className="row pt-5 justify-content-evenly row-cols-xl-2 row-cols-1 align-items-end flex-row-reverse">
                    <div className="col-lg-6 pt-5">
                        <div className="about-right">
                            <div className="section-header">
                                <span className="subtitle" style={{letterSpacing: "0px"}}>{subTitle}</span>
                                
                                <br></br>
                                <h4 className="title">{subHeading}</h4>
                                <br></br>
                                <p>- {desc1}- {desc2}</p><br></br>
                                
                                <p>- {desc3}- {desc4}</p><br></br>
                              
                                <p>- {desc5}- {desc6}</p><br></br>
                            
                                <p>- {desc7}- {desc8}</p><br></br>
                         
                                <p>- {desc9}- {desc10}</p><br></br>
                              
                                <p>- {desc11}- {desc12}</p><br></br>
                               
                                <p>- {desc13}- {desc14}</p><br></br>
                             
                                <p>- {desc15}- {desc16}</p><br></br>
                              
                                <p>- {desc17}- {desc18}- {desc19}</p><br></br>
                                <h2 className="title">{title}</h2>
                                
                                

                            </div>
                            {/* <div className="section-wrapper">
                                <ul className="lab-ul">
                                    {aboutList.map((val, i) => (
                                        <li key={i}>
                                            <div className="sr-left">
                                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                            </div>
                                            <div className="sr-right">
                                                <h5>{val.title}</h5>
                                                <p>{val.desc}</p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-lg-6 d-flex justify-content-center">
                        <div className="about-left">
                            <div className="about-thumb">
                                <img src="assets/images/about/stud.jpg" alt="about" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;