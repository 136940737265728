import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { MaharashtraDistricts } from "./districts";
export const GroupA = ({ amount, group, subheading ,groupvalue}) => {
  const [selectedDistrict, setSelectedDistrict] = useState(""); // State to track the selected district
  const [selectedTahasil, setSelectedTahasil] = useState(""); // State to track the selected taluka
  const [isDisabled, setIsDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [mobileError, setMobileError] = useState(null);
  const [aadharError, setAadharError] = useState(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [ref, setRef]=useState('')
  const [refError, setRedError]= useState('')
  const [refmsg, setRefmsg]= useState('')
  const [refmsgcColor, setRefmsgColor]= useState('')
  const [showRefBtn, setShowRefbtn]=useState(true)
  const [refDisabled,setRefDisabled]=useState(false)
  const navigate = useNavigate();

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);

    if (password !== e.target.value) {
      setPasswordError("Password and confirm password do not match.");
    } else {
      setPasswordError("");
    }
  };

  const handleDistrictChange = (e) => {
    setSelectedDistrict(e.target.value);
    setSelectedTahasil(""); // Reset the selected taluka when district changes
  };

  const handleTahasilChange = (e) => {
    setSelectedTahasil(e.target.value);
  };
  const handleMobileChange = (e) => {
    let mobileNumber = e.target.value;
    mobileNumber = mobileNumber.replace(/\D/g, ""); // Remove non-numeric characters

    if (isNaN(mobileNumber)) {
      // Block alphabetic characters
      e.preventDefault();
    }

    if (mobileNumber.length > 10) {
      mobileNumber = mobileNumber.slice(0, 10); // Truncate to 10 digits
    }

    if (mobileNumber.length < 10) {
      setMobileError("Mobile number must be at least 10 digits");
      setIsDisabled(true);
    } else {
      setMobileError(null);
      setIsDisabled(false);
    }

    // Update the input field value with the sanitized mobile number
    e.target.value = mobileNumber;
  };

  const handleAadharChange = (e) => {
    let aadharNumber = e.target.value;
    aadharNumber = aadharNumber.replace(/\D/g, "");
    if (isNaN(aadharNumber)) {
      // Block alphabetic characters
      e.preventDefault();
    }
    if (aadharNumber.length > 12) {
      aadharNumber = aadharNumber.slice(0, 12); // Truncate to 12 digits
    }

    if (aadharNumber.length < 12) {
      setIsDisabled(true);
      setAadharError("Aadhar number must be 12 digits");
    } else {
      setIsDisabled(false);
      setAadharError(null);
    }

    // Update the input field value with the sanitized mobile number
    e.target.value = aadharNumber;
  };
  const subheadingOptions = subheading.split(',').map(item => item.trim());
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setIsDisabled(true)
    // Create a new instance of FormData
    const formData = new FormData(e.target);
  
    try {
      // Make the API call using Axios
      const response = await axios.post('https://admin.sbcexam.com/student_registration.php', formData);
  
      // Handle the response here (e.g., show success message)
      console.log(response.data);
      const userId= response.data.user_id;
      const sanId=response.data.sanyojak_id;
      const sanPer=response.data.percentage;
      const sanRef=response.data.sanyojak_ref;
      const sanCat=response.data.sanyojak_category
      if(response.data.status===true){
        setLoading(false);
        setIsDisabled(false)
        
        const paymentToken= response.data.paymentkey;
    
           
        navigate('/student-payment-details',
        {state:
          {
            userId, 
            sanId, 
            sanCat, 
            sanRef, 
            sanPer,
            name: formData.get('name'), // Get the name from the form data
            mobileno: formData.get('mobileno'), // Get the mobileno from the form data
            aadhar: formData.get('aadhar'), // Get the aadhar from the form data
            sec:formData.get('sec'),
            group:formData.get('group'),
            amount:formData.get('amount'),
            email:formData.get('email'),
            address:formData.get('address')
          }
        })
        // open otp page using useNavigate
      }
      if(response.data.status===false){
        setLoading(false);
        setIsDisabled(false)
         if(response.data.validateTo==='email'){
          setEmailError(response.data.message);
         }
         else if(response.data.validateTo==='mobile'){
          setMobileError(response.data.message)
         }
         else if(response.data.validateTo==='aadhar'){
          setAadharError(response.data.message);
           }
           else if(response.data.validateTo==='exisbutnotpaid'){

            Swal.fire({
              icon: 'warning',
              title: 'तुमची Registration पूर्ण झाली आहे परंतु पेमेंट अद्याप बाकी आहे',
              text: 'देय देण्यासाठी पुढे जाण्यासाठी येथे क्लिक करा',
              allowOutsideClick:false,
              showConfirmButton:true,
              confirmButtonText:'Pay Now'  
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                navigate('/student-payment-details/',{state:
                  {
                    userId, 
                    sanId, 
                    sanCat, 
                    sanRef, 
                    sanPer,
                    name: formData.get('name'), // Get the name from the form data
                    mobileno: formData.get('mobileno'), // Get the mobileno from the form data
                    aadhar: formData.get('aadhar'), // Get the aadhar from the form data
                    sec:formData.get('sec'),
                    group:formData.get('group'),
                    amount:formData.get('amount'),
                    email:formData.get('email'),
                    address:formData.get('address')
                  }
                })
              } 
            })
           }
         
             else{
              alert(response.data.message)
             }
      }
    } catch (error) {
      setLoading(false);
      setIsDisabled(false)
      // Handle errors here (e.g., show error message)
      console.error(error);
    }
  };
  const validateRefferal =(e)=>{
    e.preventDefault()
   
    const data = {
      ref:ref,

    };
    axios.post('https://admin.sbcexam.com/studentRefVerify', data)
    .then(response => {
  
      // Handle the response data here
      console.log(response.data)
      if(response.data.status==='success'){
        setRedError("1px solid green")
        setRefmsg(response.data.message)
        setRefmsgColor("green")
        setShowRefbtn(false)
        setRefDisabled(true)
      }else{
        setRedError("1px solid red")
        setRefmsgColor("red")
        setShowRefbtn(true)
        setRefDisabled(false)
        setRefmsg(response.data.message)
      }

    })
    .catch(error => {
      console.error('Error:', error);
      // Handle errors here
    
    });
  
  }

  const removeRef =()=>{
    setShowRefbtn(true)
    setRefDisabled(false)
    setRef('')
    setRedError("")
    setRefmsgColor("")
    setRefmsg('')
  }
  return (
    <div className="account-wrapper mt-5">
      <h3 className="title">{group}</h3>
      <h6 className="event-titles">{subheading}</h6>
      <form className="account-form needs-validation"  onSubmit={handleSubmit}>
        <input type="hidden" name="group" value={groupvalue}/>
        <div className="form-group">
          <p className="form-input-headings">
            Full Name <span className="text-danger">*</span>
          </p>

          <input
            type="text"
            name="name"
            placeholder="Surname , Student Name, Father Name "
            onKeyDown={(e) => {
              const keyCode = e.keyCode || e.which;
              const keyValue = String.fromCharCode(keyCode);
              const regex = /^[a-zA-Z\s]+$/; // Regular expression to match alphabetic characters and spaces

              // Allow backspace (keyCode 8), alphabetic characters, and spaces
              if (keyCode !== 8 && !regex.test(keyValue)) {
                e.preventDefault();
              }
            }}
            required
          />
        </div>

        <div className="form-group">
          <p className="form-input-headings">
            Aadhar No. <span className="text-danger">*</span>
          </p>
          <input
            type="text"
            name="aadhar"
            placeholder="Enter Your Aadhar Number"
            onChange={handleAadharChange}
            className={aadharError ? "form-control is-invalid" : ""}
          />

          {aadharError && (
            <p className="invalid-feedback" style={{ textAlign: "left" }}>
              {aadharError}
            </p>
          )}
        </div>
        <div className="form-group">
          <p className="form-input-headings">
            Mobile Number
            <span className="text-danger">*</span>
          </p>
          <input
            type="text"
            name="mobileno"
            placeholder="Enter Your Mobile Number "
            required
            onChange={handleMobileChange}
            className={mobileError ? "form-control is-invalid" : ""}
          />
          {mobileError && (
            <p className="invalid-feedback" style={{ textAlign: "left" }}>
              {mobileError}
            </p>
          )}
        </div>
        <div className="form-group">
          <p className="form-input-headings">
          Vibhag<span className="text-danger">*</span>
          </p>

          <select type="text" name="sec" placeholder="Select Vibhag" >
            
            {subheadingOptions.map((option, index) => (
          <option key={index} value={option}>{option}</option>
        ))}
            </select>
        </div>
        {groupvalue !== 'D' && groupvalue !== 'F' && groupvalue !== 'N' && groupvalue !== 'K' && groupvalue !== 'L' && groupvalue !== 'M' && groupvalue !== 'H' && (
        <div className="form-group">
          <p className="form-input-headings">
            School/College Name 
          </p>
          <input type="text" name="school" placeholder="" />
        </div>
      )}

{(groupvalue === 'K' || groupvalue === 'L' || groupvalue === 'M') && (
  <div className="form-group">
    <p className="form-input-headings">
      Karyalaya Name
    </p>
    <input type="text" name="officename" placeholder="" />
  </div>
)}

{groupvalue === 'H' && (
        <div className="form-group">
          <p className="form-input-headings">
          College  Name
          </p>
          <input type="text" name="school" placeholder="" />
        </div>
      )}

        <div className="form-group">
          <p className="form-input-headings">
            State
            <span className="text-danger"></span>
          </p>
          <input
            type="text"
            name="state"
            value="Maharashtra"
            // placeholder="Address"
          />
        </div>

        <div className="form-group">
          <p className="form-input-headings">
            Dist. Select
            <span className="text-danger">*</span>
          </p>
          <select name="dist" onChange={handleDistrictChange} required>
            {MaharashtraDistricts.map((option) => {
              return <option value={option.name}>{option.name}</option>;
            })}
          </select>
        </div>
        {selectedDistrict && (
          <div className="form-group">
            <p className="form-input-headings">
              TalukaSelect
              <span className="text-danger">*</span>
            </p>
            <select
              name="taluka"
              value={selectedTahasil}
              onChange={handleTahasilChange}
            >
              <option value="">Select Taluka</option>
              {MaharashtraDistricts.map((option) =>
                option.name === selectedDistrict
                  ? option.tahasil.map((tahasil) => (
                      <option key={tahasil} value={tahasil}>
                        {tahasil}
                      </option>
                    ))
                  : null
              )}
            </select>
          </div>
        )}
        <div className="form-group">
          <p className="form-input-headings">
            Full Address <span className="text-danger">*</span>
          </p>

          <input type="text" name="address" placeholder="Full Address" />
        </div>
 
        <div className="form-group">
          <p className="form-input-headings">
            Email 
          </p>

          <input
            type="email"
            name="email"
            placeholder="example@gmail.com"
            onChange={() => setEmailError(null)}
            className={emailError ? "form-control is-invalid" : ""}
            required
            // placeholder="IFSC Code"
          />
          {emailError && (
            <p className="invalid-feedback" style={{ textAlign: "left" }}>
              {emailError}
            </p>
          )}
        </div>
      
          <div className="form-group" style={{position:"relative"}}>
            <p className="form-input-headings">
              Refer code
             
            </p>
            <input
              type="text"
              name="refered_by"
              placeholder="Refer code (Optional)"
              value={ref}
              readOnly={refDisabled}
              style={{border:refError}}
              onChange={
              (e)=>setRef(e.target.value)
            }
            />
            {showRefBtn? (
              <button className="btn btn-success" type="button" onClick={validateRefferal} style={{ position: "absolute", width: "100px", top: "1px", right: "2px" }}>Verify</button>
            ) : (
              <button className="btn btn-danger" type="button" onClick={removeRef} style={{ position: "absolute", width: "100px", top: "1px", right: "2px" }}>Remove</button>
            )}
          
            {refError && <p  style={{textAlign:"left", fontSize:"0.85rem", color:refmsgcColor}}>{refmsg}</p>}
          </div>

          
      
        <div className="form-group">
          <p className="form-input-headings">
            Registration fee
            <span className="text-danger">*</span>
          </p>
          <input
            type="text"
            name="amount"
            value={amount}
           
            // placeholder="स्वत"
          />
        </div>
        <div className="form-group">
          <p className="form-input-headings">
            Password
            <span className="text-danger">*</span>
          </p>
          <input
            type="password"
            name="password"
            value={password}
            onChange={handlePasswordChange}
            className={passwordError ? "form-control is-invalid" : "is-valid"}
          />
        </div>

        <div className="form-group">
          <p className="form-input-headings">
            Confirm Password 
            <span className="text-danger">*</span>
          </p>
          <input
            type="password"
            name="confirmPassword"
            value={confirmPassword}
            className={passwordError ? "form-control is-invalid" : "is-valid"}
            onChange={handleConfirmPasswordChange}
          />
          {passwordError && (
            <p className="invalid-feedback" style={{ textAlign: "left" }}>
              {passwordError}
            </p>
          )}
        </div>
        <div className="form-group">
          <button className="lab-btn my-2" type="submit" disabled={isDisabled}>
            {loading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm me-3 text-white"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span>Processing...</span>
              </>
            ) : (
              <span>Register and Pay</span>
            )}
          </button>
        </div>
      </form>
      <div className="account-bottom">
        <span className="d-block cate pt-10">
          Are You Registerd? <Link to="/login">Login Now</Link>
        </span>
      </div>
    </div>
  );
};
