
import { useState } from "react";
import { RajyaSanyojakForm } from "./RajyaSanyojakForm";



const Category3 = ({amount, hideReferral, sanyojakNiyam, category}) => {
    const [paid ,setPaid] = useState(false)

    return (
        <div className="category-section pt-5">
            <div className="container">
                <div className="section-header text-center">
                    {/* <span className="subtitle">{subTitle}</span> */}
                    {/* <h2 className="title">{title}</h2> */}
                    {/* <h2 className="title">Coordinator नियम</h2> */}
                </div>
                <div className="section-wrapper">
                    {/* <div className="row g-2 justify-content-center row-cols-xl-6 row-cols-md-3 row-cols-sm-2 row-cols-1">
                        {categoryList.map((val, i) => (
                            <div className="col" key={i}>
                                <div className="category-item text-center">
                                    <div className="category-inner">
                                        <div className="category-thumb">
                                            <img src={`${val.imgUrl}`} alt={val.imgAlt} />
                                        </div>
                                        <div className="category-content">
                                            <Link to="/course"><h6>{val.title}</h6></Link>
                                            <span>{val.count}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        ))}
                    </div> */}
                    <div className="row g-2 gx-5 justify-content-evenly">
                        {/* <div className="col-lg-4 col-md-8 d-flex justify-content-center">
                            <img src="assets/images/instructor/team.jpg" className="category-image" />
                        </div> */}
                         <div className="col-md-6 about-us-description pb-5 text-center">
                         <h2 className="title">Coordinator नियम</h2>
                         </div>
                         <div className="col-md-6 about-us-description pb-5 text-center">
                         <h2 className="title">Registration फॉर्म</h2>
                         </div>
                        <div className="col-md-6 about-us-description pb-5">
                            {sanyojakNiyam.map((desc)=>{
                                return (
                                    <p>{desc}</p>
                                )
                            })}
                        </div>
                        <div className="col-md-6 about-us-description">
                            <RajyaSanyojakForm category={category}  paid={paid} amount={amount} hideReferral={hideReferral}/>
                        </div>
                    </div>
                    {/* <div className="text-center mt-5">
                        <Link to="/about" className="lab-btn"><span>{btnText}</span></Link>
                    </div> */}
                </div>
            </div>
        </div>
    );
    
}

export default Category3;