import { Link } from "react-router-dom";

const newsTitle = "Want Us To Email You About Special Offers And Updates?";
const siteTitle = "Sbc exam";
const useTitle = "Important";
const socialTitle = "Social Work";
const supportTitle = "Our Support";

const siteList = [
  // {
  //   text: "प्रमाणपत्र / प्रशस्तीपत्र",
  //   link: "/instructor",
  // },
  {
    text: "Photo",
    link: "/gallery",
  },
  {
    text: "Video",
    link: "/video-gallery",
  },
  {
    text: "About Us",
    link: "/about",
  },
  {
    text: "Terms & Conditions",
    link: "/privacy-policy",
  },
  {
    text: "Privacy policy",
    link: "/privacy-policy",
  },
];

const useList = [
    // {
    //   text: "लेख",
    //   link: "/blog",
    // },
  {
    text: "Photo Gallery",
    link: "/gallery",
  },
  {
    text: "Video",
    link: "/video-gallery",
  },
  // {
  //   text: "मार्गदर्शक",
  //   link: "/pramukh-margdarshak",
  // },
  // {
  //   text: "मुख्य कायदेविषयक सल्लागार समिती",
  //   link: "/mukhai",
  // },
  // {
  //   text: "Stateसमन्वय समिती",
  //   link: "/rajya-simti",
  // },
];

const socialList = [
  {
    text: "Facebook",
    link: "#",
  },
  {
    text: "Twitter",
    link: "#",
  },
  {
    text: "Instagram",
    link: "#",
  },
  {
    text: "YouTube",
    link: "#",
  },
 
];

const supportList = [
 
  {
    text: "Contact Us",
    link: "/contact",
  },
 
];

const Footer = () => {
  return (
    <div className="news-footer-wrap">
      <div className="fs-shape">
        <img src="assets/images/shape-img/03.png" alt="fst" className="fst-1" />
        <img src="assets/images/shape-img/04.png" alt="fst" className="fst-2" />
      </div>

      {/* <div className="news-letter">
        <div className="container">
          <div className="section-wrapper">
            <div className="news-title">
              <h3>{newsTitle}</h3>
            </div>
            <div className="news-form">
              <form action="/">
                <div className="nf-list">
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter Your Email"
                  />
                  <input type="submit" name="submit" value="Subscribe Now" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}

      <footer className="">
        <div className="footer-top padding-tb pt-5">
          <div className="container">
            <div className="row g-4 row-cols-xl-4 row-cols-md-2 row-cols-1 justify-content-center">
              <div className="col">
                <div className="footer-item">
                  <div className="footer-inner">
                    <div className="footer-content">
                      <div className="title">
                        <h4>{siteTitle}</h4>
                      </div>
                      <div className="content">
                        <ul className="lab-ul">
                          {siteList.map((val, i) => (
                            <li key={i}>
                              <a href={val.link}>{val.text}</a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="footer-item">
                  <div className="footer-inner">
                    <div className="footer-content">
                      <div className="title">
                        <h4>About Mission</h4>
                      </div>
                      <div className="content">
                        <ul className="lab-ul">
                          {useList.map((val, i) => (
                            <li key={i}>
                              <a href={val.link}>{val.text}</a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="footer-item">
                  <div className="footer-inner">
                    <div className="footer-content">
                      <div className="title">
                        <h4>{socialTitle}</h4>
                      </div>
                      <div className="content">
                        <ul className="lab-ul">
                          {socialList.map((val, i) => (
                            <li key={i}>
                              <a href={val.link}>{val.text}</a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="footer-item">
                  <div className="footer-inner">
                    <div className="footer-content">
                      <div className="title">
                        <h4>{supportTitle}</h4>
                      </div>
                      <div className="content">
                        <ul className="lab-ul">
                          {supportList.map((val, i) => (
                            <li key={i}>
                              <a href={val.link}>{val.text}</a>
                            </li>
                            
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>   
          
        <div className="footer-bottom style-1">
          <div className="container">
            <div className="section-wrapper">
            <p>
                
                <Link to="/">
                STUDENT BANEGA CROREPATI
                  2023-24
                </Link>
              </p>
              
              <p>
                
                {" "}
                Powered by{" "}
                <a href="#" target="_blank">
                PERFECT FINANCIAL SERVICE
                </a>{" "}
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
