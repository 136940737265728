import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import AchievementTwo from "../component/section/achievement-2";
import Blog from "../component/section/blog";
import Instructor from "../component/section/instructor";
import Skill from "../component/section/skill";
import Student from "../component/section/student";

const subTitle = "प्रमुख मार्गदर्शक";

const names = [
    "मा. बी .जी . कोळसे पाटील (निवृत्त न्यायमूर्ती )",
    "माँ अँड डॉ सुरेशजी माने (संविधान तज्ञ राष्ट्रीय अध्यक्ष बी आर एस पी )",
    "मा .इ . झेड . खोब्रागडे (संविधान फाउंडेशन , निवृत्त -आय ए एस )",
    "मा सुनील भालेराव (मुख्य Coordinator एन एन एफ ठाणे )",
    "मा राजेश कांबळे, मा सुधीर राज, मा रमेश कटके ( समन संघ ठाणे )",
    "मा मिलिंद आव्हाड (मानवी हक्क अभियान राष्ट्रीय अध्यक्ष, जे एन यु प्रोफेसर दिल्ली )",
    "मा सुशीला ताई मोराळे ( जनता दल राष्ट्रिय महासचिव )",
    "मा डॉ अनंत राऊत (प्रसिद्ध लेखक विचारवंत संविधान अभ्यासक तथा वाय पी एम इंडियन क्रांती नांदेड )",
    "डॉ बालाजी जाधव ( संभाजी ब्रिगेड महाराष्ट्र Stateसंघटक )",
    "मा .प्रदीप दादा सोळुंके ( प्रसिद्ध ह. भ. प. प्रबोधनकार तथा बी आर एस ज्येष्ठ नेते )",
    "मा.ऍड सर्वजित बनसोडे (Stateअध्यक्ष वंचित बहुजन आघाडी पुणे )",
    "मा सिद्धार्थ शिनगारे (बामसेफ Stateमहासचिव )",
    "मा सुनील मगरे (महाराष्ट्र कार्याध्यक्ष सामाजिक न्याय Vibhagरा.कॉ.,मुप्टा शिक्षक संघटना संस्थापक तथा सचिव,विद्यापीठ सिनेट सदस्य )",
    "मा रंगनाथ चव्हाण ( एम एस ई बी एन टी Vibhagप्रमुख )",
    "मा.सुधाकर चव्हाण (विद्यापीठ सिनेट सदस्य तथा वसंतराव नाईक अधिकारी कर्मचारी संघटना Dist. अध्यक्ष छ. संभाजीनगर )",
    "मा सुनील निकम (प्राचार्य न्यू हायस्कूल उच्च माध्यमिक विद्यालय धांदलगाव तथा विद्यापीठ सिनेट सदस्य )",
    "मा पृथ्वीराज साठे (मा आमदार रॉ. कॉ.केज विधानसभा )",
    "मा सिद्धार्थ टाकणखार (प्रदेश सचिव बि एस पी )",
    "मा.अब्दुल वाजिद कादरी ( जमाती इस्लामी हिंद छ.संभाजीनगर )",
    "मा .भास्कर सरोदे (संपादक बहुजन शासक )",
    "मा किसान थुल (सचिव डॉ.बा . आं.स्मारक समिती तळेगाव दाभाडे )", 
    "मा .अनिल कुमार साळवे (प्रसिद्ध मराठी चित्रपट दिग्दर्शक )",
    "मा.अजिंक्य भैय्या चांदणे (प्रदेशाध्यक्ष डि पी आय बीड )",
   " मा . मनिषा ताई तोकले ( सामाजिक कार्यकर्त्या बीड )",
    "मा .के .टी .उपदेशे (प्रसिद्ध लेखक )",
    "मा प्रवीण कुमार डावरे (अध्यक्ष सामाजिक समता अभियान )",
    "मा .भारत टाकणखार (महाराष्ट्र Stateकास्ट्राईब कर्मचारी कल्याण महासंघ )"
]


const aboutList = [
    {
        imgUrl: 'assets/images/about/icon/01.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Skilled Instructors',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
    {
        imgUrl: 'assets/images/about/icon/02.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Get Certificate',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
    {
        imgUrl: 'assets/images/about/icon/03.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Online Classes',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
]


const PramukhMargdarshak = () => {
    return (
        <Fragment>
            <Header />
            <PageHeader title={'प्रमुख मार्गदर्शक'} curPage={'प्रमुख मार्गदर्शक'} />
            <div className="about-section style-3 padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-12 col-sm-8">
                            <div className="about-right">
                                <div className="section-header">
                                    <span className="subtitle">{subTitle}</span>
                                    {/* <h2 className="title">{title}</h2> */}
                                    {names.map((name, index)=>{
                                        return (
                                            <p className="privacy-policy-text" key={index}>
                                        {name}    
                                    </p>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default PramukhMargdarshak;
