import { Component, Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import Achievement from "../component/section/achievement";
import Student from "../component/section/student";
import Category2 from "../component/section/category2"
import Category3 from "../component/section/category3"

const subTitle = "Prakash Sonawane";
const title = "हमारा संकल्प सुशिक्षीत, सुरक्षित, समृद्ध भारत";
const title2 = "Make A Global Impact";
const btnText = "Browse All Categories";
const description = "Dist. Coordinator हे संविधान जनजागृती अभियान याचा प्रसार व प्रचार करण्यासाठी तसेच परीक्षार्थीची Registration करून घेण्यासाठी Taluka Coordinator , Gut Coordinator यांची नेमणूक करण्याचा अधिकार Dist. Coordinatorास आहे. Dist. संजोयक हे अभियानाच्या प्रक्रियेत सूचना, बदल, व नियोजन प्रक्रियेत मार्गदर्शक म्हणून देखील सहभागी होऊ शकतील"
const sanyojakNiyam=[
    "- Dist. Coordinator यांनी स्वतःच्या रेफरल आयडी वर केलेल्या तालुका, Gut Coordinator सर्वांनी मिळून केलेल्या Registrationवरच ५ % प्रवासभत्ता / मानधन म्हणून मिळेल",
    "- निर्धारित केलेल्या परीक्षार्थी संख्या पूर्ण न केल्यास सदरील Coordinator बक्षीस पात्र नसेल.",
    "- Coordinatorांनी आपला परीक्षार्थी Registration डाटा ऑनलाईन वेबसाईटवर देखील उपलब्ध असेल परंतु लेखी स्वरूपात डायरीमध्ये संकलित करून ठेवणे आवश्यक असेल.",
    "- कोणत्याही Coordinatorाकडून गैरवर्तन अथवा कामांमध्ये दोष आढळून आल्यास सदरील Coordinatorास कमी करण्याचा कायमस्वरूपी काढून टाकण्याचा निर्णय आयोजकाअधीन असेल.",
    "- कोणत्याही Coordinatorाची नियुक्ती ही कायमस्वरूपी नसून अभियानाच्या काळापूर्ती मर्यादित असेल."
]


let categoryItemList = [
    {
        imgUrl: 'assets/images/instructor/single/categoryimage.png',
        imgAlt: 'category',
        title: 'मा.बिभिषण शिंदे',
        desc: '(छ.संभाजी नगर)',
    },
    {
        imgUrl: 'assets/images/instructor/sanket-kamre.jpeg',
        imgAlt: 'category',
        title: 'मा.संकेत कांबळे',
        desc: '(छ.संभाजी नगर)',
    },
    {
        imgUrl: 'assets/images/instructor/arung-pagare.jpeg',
        imgAlt: 'category',
        title: 'मा.अरुण पगारे',
        desc: '(छ.संभाजी नगर)',
    },
    {
        imgUrl: 'assets/images/instructor/deepak.jpg',
        imgAlt: 'category',
        title: 'मा.दिपक चौगुले',
        desc: '(बीड)',
    },
    {
        imgUrl: 'assets/images/instructor/pradeep.jpg',
        imgAlt: 'category',
        title: 'मा.प्रदिप जाधव',
        desc: '(बीड)',
    },
    {
        imgUrl: 'assets/images/instructor/dr-di-m.jpg',
        imgAlt: 'category',
        title: 'डॉ.डी.एम. आखाडे',
        desc: '(जळगाव)',
    },
    {
        imgUrl: 'assets/images/instructor/bharat-madke.jpeg',
        imgAlt: 'category',
        title: 'मा. भारत मडके',
        desc: '(कांदिवली)',
    },
    {
        imgUrl: 'assets/images/instructor/single/categoryimage.png',
        imgAlt: 'category',
        title: 'प्रा.गणेश गायसमुद्रे',
        desc: '(आदिवासी विकास Vibhagनाशिक)',
    },
    {
        imgUrl: 'assets/images/instructor/ravi.jpg',
        imgAlt: 'category',
        title: 'मा.रवि औसरमल',
        desc: '(विदर्भ विभाग)',
    },
    {
        imgUrl: 'assets/images/instructor/devanand.jpg',
        imgAlt: 'category',
        title: 'मा.देवानंद उराडे',
        desc: '(गडचिरोली)',
    },
    {
        imgUrl: 'assets/images/instructor/single/categoryimage.png',
        imgAlt: 'category',
        title: 'अड.प्रदिप घेवंदे',
        desc: '(बुलढाणा)',
    },
    {
        imgUrl: 'assets/images/instructor/single/categoryimage.png',
        imgAlt: 'category',
        title: 'मा.बाबुलाल ढोरमारे',
        desc: '(Dist. अध्यक्ष, धनगर समाज संघर्ष समिती, बीड)',
    },
    {
        imgUrl: 'assets/images/instructor/single/categoryimage.png',
        imgAlt: 'category',
        title: 'मा.माणिक वाघमारे',
        desc: '(अध्यक्ष, इमारत व इतर बांधकाम मजूर संघटना,भारतीय सरपंच महासंघ महाराष्ट्र State)',
    },
 
    {
        imgUrl: 'assets/images/instructor/single/categoryimage.png',
        imgAlt: 'category',
        title: 'योगेश गायकवाड',
        desc: '(सामाजिक कार्यकर्ते)',
    },
    {
        imgUrl: 'assets/images/instructor/single/categoryimage.png',
        imgAlt: 'category',
        title: 'मा. शिवाजी हजारे',
        desc: 'केज',
    },
    {
        imgUrl: 'assets/images/instructor/premji-hazare.jpeg',
        imgAlt: 'category',
        title: 'मा. प्रेमजीत हजारे',
        desc: 'माहिती अधिकार कार्यकर्ते',
    },
    {
        imgUrl: 'assets/images/instructor/prachi.jpeg',
        imgAlt: 'category',
        title: 'मा .प्राची जाधव',
        desc: 'संचालिका : रुधिर डायग्नोस्टिक घनसोली मुंबई',
    },
   
]



const JilhaSanyojak = () => {

    const [viewMore, setViewMore] = useState(false)

    const handleClickScroll = () => {
        const element = document.getElementById('form-wrapper');
        if (element) {
          // 👇 Will scroll smoothly to the top of the next section
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };

    return (
        <Fragment>
            <Header />
            <PageHeader title={'Dist. Coordinator'} curPage={'Dist. Coordinator'} />
            <Category2 description={description} img={"assets/images/category/jilha.png"}  scroll={handleClickScroll} interchange={true}/>
            <div className="category-section padding-tb section-bg style-3">
                <div className="container pb-5">
                    <div className="section-header text-center">
                        <span className="subtitle" style={{letterSpacing: "0px"}}>{subTitle}</span>
                        <h2 className="title">{title}</h2>
                        {/* <h2 className="title">{title2}</h2> */}
                    </div>
                    <div className="section-wrapper">
                        <div className="row g-4 justify-content-center row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-1">
                            {categoryItemList.map((val, i) => (
                                viewMore ? (
                                    <div className="col" key={i}>
                                        <div className="category-item text-center h-100">
                                            <div className="category-inner h-100">
                                                <div className="category-thumb">
                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} className="category-image" />
                                                </div>
                                                <div className="category-content">
                                                    <h4>{val.title}</h4>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    i < 4 &&
                                    <div className="col" key={i}>
                                        <div className="category-item text-center h-100">
                                            <div className="category-inner h-100">
                                                <div className="category-thumb">
                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} className="category-image" />
                                                </div>
                                                <div className="category-content">
                                                    <h4>{val.title}</h4>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )

                            ))}
                        </div>
                        <div className="row">

                        </div>
                        <div className="text-center mt-5">
                            <button onClick={() => { setViewMore(!viewMore) }} className="lab-btn"><span>{viewMore ? "See less" : "View more"}</span></button>
                        </div>
                    </div >
                </div >
                <Category3 category="jilha-sanyojak" sanyojakNiyam={sanyojakNiyam} amount={500}/>
            </div >
            {/* <Student /> */}
            < Achievement />
            <Footer />
        </Fragment >
    );
}

export default JilhaSanyojak;