import React from 'react'
import Header from '../component/layout/header';
import Footer from '../component/layout/footer';
import { Fragment } from 'react';
import Iframe from 'react-iframe'
const ProceedToPay = () => {
  return (
    <Fragment>
        <Header />
        <div className="login-section padding-tb section-bg">
        <div className='container' style={{marginTop:"5rem"}}>
        <Iframe url="https://www.sdrive.app/embed/1ptBQD"
        width="100%"
        height="auto"
        id=""
        className=""
        display="block"
        position="relative"/>
        </div>
        </div>
        <Footer />

    </Fragment>
  )
}

export default ProceedToPay