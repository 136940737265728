import React from 'react'
import { Link } from 'react-router-dom'
const Books = () => {
  return (
    <div className="blog-section padding-tb section-bg">
                <div className="container">
                <div className="section-header text-center">
                    
                    <h2 className="title"> Constitution of India </h2>
                </div>
                    <div className="section-wrapper">
                        <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 justify-content-center g-4">
                    
                                <div className="col" >
                                    <div className="post-item">
                                        <div className="post-inner">
                                            <div className="post-thumb">
                                                <img src="assets/images/books/savidhan_marathi.png" style={{ width:"100%"}} />
                                            </div>
                                            <div className="post-content text-center">
                                                <h6>Constitution Marathi PDF</h6>
                                            </div>
                                            <div className='d-flex justify-content-center'>
                                                    <a href='assets/pdf/sanvidhan_marathi.pdf' target='blank' type='button' className='lab-btn text-white'>Download Now   <i className="icofont-download ms-2"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col" >
                                    <div className="post-item">
                                        <div className="post-inner">
                                            <div className="post-thumb">
                                                <img src="assets/images/books/savidhan_hindi.webp" style={{ width:"100%"}} />
                                            </div>
                                            <div className="post-content text-center">
                                                <h6>Constitution Hindi PDF</h6>
                                            </div>
                                            <div className='d-flex justify-content-center'>
                                                    <a href='assets/pdf/coi-hindi.pdf' target='blank' type='button' className='lab-btn text-white'>Download Now <i className="icofont-download ms-2"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col" >
                                    <div className="post-item">
                                        <div className="post-inner">
                                            <div className="post-thumb">
                                                <img src="assets/images/books/81xm5fNKsRL.jpg" style={{ width:"100%"}} />
                                            </div>
                                            <div className="post-content text-center">
                                                <h6>Constitution English PDF</h6>
                                            </div>
                                            <div className='d-flex justify-content-center'>
                                                    <a href='assets/pdf/Constitution-of-India-Updated-to-103rd-Amendment-Jan-2019.pdf' target='blank' type='button' className='lab-btn text-white'>Download Now <i className="icofont-download ms-2"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        
                        </div>
                       
                    </div>
                </div>
            </div>
  )
}

export default Books