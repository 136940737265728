import { Component, Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import Achievement from "../component/section/achievement";
import Student from "../component/section/student";
import Category2 from "../component/section/category2"
import Category3 from "../component/section/category3"

const subTitle = "Prakash Sonawane";
const title = "Discover Your Potential";
const title2 = "Make A Global Impact";
const btnText = "Browse All Categories";
const description = "Taluka Coordinator हे संविधान जनजागृती अभियान याचा प्रसार व प्रचार करण्यासाठी तसेच परीक्षार्थीची Registration करून घेण्यासाठी Gut Coordinator यांची नेमणूक करण्याचा अधिकार Taluka Coordinatorास आहे. Talukaसंजोयक हे अभियानाच्या प्रक्रियेत सूचना, बदल, व नियोजन प्रक्रियेत मार्गदर्शक म्हणून देखील सहभागी होऊ शकतील."
const sanyojakNiyam=[
    "- Taluka Coordinator यांनी स्वतःच्या रेफरल आयडी वर केलेल्या Gut Coordinatorांनी केलेल्या Registrationवरच ७% प्रवासभत्ता / मानधन म्हणून मिळेल",
    "- निर्धारित केलेल्या परीक्षार्थी संख्या पूर्ण न केल्यास सदरील Coordinator बक्षीस पात्र नसेल.",
    "- Coordinatorांनी आपला परीक्षार्थी Registration डाटा ऑनलाईन वेबसाईटवर देखील उपलब्ध असेल परंतु लेखी स्वरूपात डायरीमध्ये संकलित करून ठेवणे आवश्यक असेल.",
    "- कोणत्याही Coordinatorाकडून गैरवर्तन अथवा कामांमध्ये दोष आढळून आल्यास सदरील Coordinatorास कमी करण्याचा कायमस्वरूपी काढून टाकण्याचा निर्णय आयोजकाअधीन असेल.",
    "- कोणत्याही Coordinatorाची नियुक्ती ही कायमस्वरूपी नसून अभियानाच्या काळापूर्ती मर्यादित असेल."
]


let categoryItemList = [
    {
        imgUrl: 'assets/images/instructor/single/categoryimage.png',
        imgAlt: 'category',
        title: 'मा.पंकज बनसोडे',
        desc: 'Like graphic design, business anaytics coding and much more',
    },
    {
        imgUrl: 'assets/images/instructor/single/categoryimage.png',
        imgAlt: 'category',
        title: 'मा. बाबासाहेब मनेरे',
        desc: 'Like graphic design, business anaytics coding and much more',
    },
    {
        imgUrl: 'assets/images/instructor/rajkumar-kamble.jpg',
        imgAlt: 'category',
        title: 'मा. राजकुमार कांबळे',
        desc: 'Like graphic design, business anaytics coding and much more',
    },
    {
        imgUrl: 'assets/images/instructor/single/categoryimage.png',
        imgAlt: 'category',
        title: 'मा.पवन लाटे',
        desc: 'Like graphic design, business anaytics coding and much more',
    },
    {
        imgUrl: 'assets/images/instructor/single/categoryimage.png',
        imgAlt: 'category',
        title: 'मा.धम्मपाल विद्यागर',
        desc: 'Like graphic design, business anaytics coding and much more',
    },
    {
        imgUrl: 'assets/images/instructor/single/categoryimage.png',
        imgAlt: 'category',
        title: 'मा. संदिपभाई निकुंभ',
        desc: 'Like graphic design, business anaytics coding and much more',
    },
    {
        imgUrl: 'assets/images/instructor/single/categoryimage.png',
        imgAlt: 'category',
        title: 'मा. महेश तांदळे',
        desc: 'Like graphic design, business anaytics coding and much more',
    },
    {
        imgUrl: 'assets/images/instructor/single/categoryimage.png',
        imgAlt: 'category',
        title: 'मा.मारोती खंदारे',
        desc: 'Like graphic design, business anaytics coding and much more',
    },
    {
        imgUrl: 'assets/images/instructor/single/categoryimage.png',
        imgAlt: 'category',
        title: 'मा.शैलेश कांबळे',
        desc: 'Like graphic design, business anaytics coding and much more',
    },
    {
        imgUrl: 'assets/images/instructor/single/categoryimage.png',
        imgAlt: 'category',
        title: 'मा.नामदेवराव आयवळे',
        desc: 'Like graphic design, business anaytics coding and much more',
    },
    {
        imgUrl: 'assets/images/instructor/single/categoryimage.png',
        imgAlt: 'category',
        title: 'मा.मधुकर घायदार',
        desc: 'Like graphic design, business anaytics coding and much more',
    },
    {
        imgUrl: 'assets/images/instructor/single/categoryimage.png',
        imgAlt: 'category',
        title: 'मा.लक्ष्मण पोटभरे',
        desc: 'Like graphic design, business anaytics coding and much more',
    },
    {
        imgUrl: 'assets/images/instructor/single/categoryimage.png',
        imgAlt: 'category',
        title: 'मा.फुलचंद डोंगरे',
        desc: 'Like graphic design, business anaytics coding and much more',
    },
    {
        imgUrl: 'assets/images/instructor/single/categoryimage.png',
        imgAlt: 'category',
        title: 'मा. खंडु जाधव',
        desc: 'Like graphic design, business anaytics coding and much more',
    },

]



const TalukaSanyojak = () => {

    const [viewMore, setViewMore] = useState(false)

    const handleClickScroll = () => {
        const element = document.getElementById('form-wrapper');
        if (element) {
          // 👇 Will scroll smoothly to the top of the next section
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };

    return (
        <Fragment>
            <Header />
            <PageHeader title={'Taluka Coordinator'} curPage={'Taluka Coordinator'} />
            <Category2 img={"assets/images/category/taluka.jpg"} description={description}  scroll={handleClickScroll}/>
            <div className="category-section padding-tb section-bg style-3">
                {/* <div className="container pb-5">
                    <div className="section-header text-center">
                        <span className="subtitle" style={{letterSpacing: "0px"}}>{subTitle}</span>
                        <h2 className="title">{title}</h2>
                        <h2 className="title">{title2}</h2>
                    </div>
                    <div className="section-wrapper">
                         <div className="row g-4 justify-content-center row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-1">
                            {categoryItemList.map((val, i) => (
                                viewMore ? (
                                    <div className="col" key={i}>
                                        <div className="category-item text-center h-100">
                                            <div className="category-inner h-100">
                                                <div className="category-thumb">
                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} className="category-image" />
                                                </div>
                                                <div className="category-content">
                                                    <Link to="/signup"><h4>{val.title}</h4></Link>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    i < 4 &&
                                    <div className="col" key={i}>
                                        <div className="category-item text-center h-100">
                                            <div className="category-inner h-100">
                                                <div className="category-thumb">
                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} className="category-image" />
                                                </div>
                                                <div className="category-content">
                                                    <Link to="/signup"><h4>{val.title}</h4></Link>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )

                            ))}
                        </div> 
                        <div className="row">

                        </div>
                        <div className="text-center mt-5">
                            <button onClick={() => { setViewMore(!viewMore) }} className="lab-btn"><span>{viewMore ? "See less" : "View more"}</span></button>
                        </div>
                    </div >
                </div > */}
                <Category3 category="taluka-sanyojak"  sanyojakNiyam={sanyojakNiyam} amount={500}/>
            </div >
            {/* <Student /> */}
            < Achievement />
            <Footer />
        </Fragment >
    );
}

export default TalukaSanyojak;