import React, {useState} from "react";
import { MaharashtraDistricts } from "../component/section/districts";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
const Shabhasad = ({ amount, title }) => {
    const [selectedDistrict, setSelectedDistrict] = useState(""); // State to track the selected district
    const [selectedTahasil, setSelectedTahasil] = useState(""); // State to track the selected taluka
    const [loading, setLoading] = useState(false);
    const [emailError, setEmailError] = useState(null);
    const [mobileError, setMobileError] = useState(null);
    const [aadharError, setAadharError] = useState(null);
    const [isDisabled, setIsDisabled] = useState(true);
  
  
  
    
    const navigate = useNavigate();
  
   
  
 
    const handleDistrictChange = (e) => {
      setSelectedDistrict(e.target.value);
      setSelectedTahasil(""); // Reset the selected taluka when district changes
    };
  
    const handleTahasilChange = (e) => {
      setSelectedTahasil(e.target.value);
    };
  
  const handleMobileChange = (e) => {
    let mobileNumber = e.target.value;
    mobileNumber = mobileNumber.replace(/\D/g, ""); // Remove non-numeric characters
  
    if (isNaN(mobileNumber)) {
      // Block alphabetic characters
      e.preventDefault();
    }
  
    if (mobileNumber.length > 10) {
      mobileNumber = mobileNumber.slice(0, 10); // Truncate to 10 digits
    }
  
    if (mobileNumber.length < 10) {
      setMobileError("Mobile number must be at least 10 digits");
      setIsDisabled(true);
    } else {
      setMobileError(null);
      setIsDisabled(false)
    }
  
    // Update the input field value with the sanitized mobile number
    e.target.value = mobileNumber;
  };
  
  
  
  const handleAadharChange = (e) => {
    let aadharNumber = e.target.value;
    aadharNumber=aadharNumber.replace(/\D/g, "");
    if (isNaN(aadharNumber)) {
      // Block alphabetic characters
      e.preventDefault();
    }
      if (aadharNumber.length > 12) {
        aadharNumber = aadharNumber.slice(0, 12); // Truncate to 12 digits
    }
  
    if (aadharNumber.length < 12) {
      setIsDisabled(true)
      setAadharError("Aadhar number must be 12 digits");
    } else {
      setIsDisabled(false)
      setAadharError(null);
    }
  
    // Update the input field value with the sanitized mobile number
    e.target.value = aadharNumber;
  };
  

  

  
  
  
  const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      setIsDisabled(true)
      // Create a new instance of FormData
      const formData = new FormData(e.target);
    
      try {
        // Make the API call using Axios
        const response = await axios.post('https://admin.sbcexam.com/sabhasadRegister', formData);
    
        // Handle the response here (e.g., show success message)
        console.log(response.data);
        if(response.data.status===true){
          setLoading(false);
          setIsDisabled(false)
          
          const paymentToken= response.data.paymentkey;
      
             
          navigate(`/sabhasad-payment-details/${paymentToken}`)
          // open otp page using useNavigate
        }
        if(response.data.status===false){
          setLoading(false);
          setIsDisabled(false)
           if(response.data.validateTo==='email'){
            setEmailError(response.data.message);
           }
           else if(response.data.validateTo==='mobile'){
            setMobileError(response.data.message)
           }
           else if(response.data.validateTo==='aadhar'){
            setAadharError(response.data.message);
             }
             else if(response.data.validateTo==='exisbutnotpaid'){
              const paymentToken= response.data.paymentkey;
              Swal.fire({
                icon: 'warning',
                title: 'तुमची Registration पूर्ण झाली आहे परंतु पेमेंट अद्याप बाकी आहे',
                text: 'देय देण्यासाठी पुढे जाण्यासाठी येथे क्लिक करा',
                allowOutsideClick:false,
                showConfirmButton:true,
                confirmButtonText:'Pay Now'  
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  navigate(`/sabhasad-payment-details/${paymentToken}`)
                } 
              })
             }
          
               else{
                alert(response.data.message)
               }
        }
      } catch (error) {
        setLoading(false);
        setIsDisabled(false)
        // Handle errors here (e.g., show error message)
        console.error(error);
      }
    };

  return (
    <div className="account-wrapper mt-5" id="form-wrapper">
      {title && <h3 className="title">{title}</h3>}
      <form className="account-form needs-validation" onSubmit={handleSubmit}>
    
        <div className="form-group">
          <p className="form-input-headings">
            Name
            <span className="text-danger">*</span>
          </p>

          <input
            type="text"
            name="name"
            onKeyDown={(e) => {
                const keyCode = e.keyCode || e.which;
                const keyValue = String.fromCharCode(keyCode);
                const regex = /^[a-zA-Z\s]+$/; // Regular expression to match alphabetic characters and spaces

                // Allow backspace (keyCode 8), space (keyCode 32), and alphabetic characters
                if (keyCode !== 8 && keyCode !== 32 && !regex.test(keyValue)) {
                e.preventDefault();
                }
            }}
            required
            />
        </div>
  

  <div className="form-group">
    <p className="form-input-headings">
      Aadhar No. <span className="text-danger">*</span>
    </p>
    <input
      type="tel"
      name="aadhar"
      required
      onChange={handleAadharChange}
      className={aadharError ? 'form-control is-invalid' : ''}
    />
    {aadharError && <p className="invalid-feedback" style={{ textAlign: "left" }}>{aadharError}</p>}
  </div>

          <div className="form-group">
            <p className="form-input-headings">
              Mobile Number
              <span className="text-danger">*</span>
            </p>
            <input
              type="text"
              name="mobileno"
              required
              onChange={handleMobileChange}
              className={mobileError ? 'form-control is-invalid' : ''}
            // placeholder="Address"
            />
            {mobileError && <p className="invalid-feedback" style={{ textAlign: "left" }}>{mobileError}</p>}
          </div>
      <div className="form-group">
          <p className="form-input-headings">
             State
            <span className="text-danger"></span>
          </p>
          <input
            type="text"
            name="state"
            value="Maharashtra"
          // placeholder="Address"
          />
        </div>

        <div className="form-group">
          <p className="form-input-headings">
             Dist. Select
            <span className="text-danger">*</span>
          </p>
          <select name="dist" onChange={handleDistrictChange} required >
            {MaharashtraDistricts.map((option)=>{
              return(
                <option value={option.name}>{option.name}</option>
              )
            })}
          </select>
        </div>
        {selectedDistrict && (
          <div className="form-group">
            <p className="form-input-headings">
              TalukaSelect
              <span className="text-danger" >*</span>
            </p>
            <select name="taluka" value={selectedTahasil} onChange={handleTahasilChange}>
              <option value="">Select Taluka</option>
              {MaharashtraDistricts.map((option) =>
                option.name === selectedDistrict ? (
                  option.tahasil.map((tahasil) => (
                    <option key={tahasil} value={tahasil}>
                      {tahasil}
                    </option>
                  ))
                ) : null
              )}
            </select>
          </div>
        )}
        <div className="form-group">
          <p className="form-input-headings">
          Address :
            <span className="text-danger">*</span>
          </p>
          <input
            type="text"
            name="address"
            required
          // placeholder="IFSC Code"
          />
        </div>

  
    


        <div className="form-group">
          <p className="form-input-headings">
            Email id <span className="text-danger">*</span>
          </p>
          <input
            type="email"
            name="email"
            required
            onChange={() =>setEmailError(null)}
            className={emailError ? 'form-control is-invalid' : ''}
            // placeholder="IFSC Code"
          />
          {emailError && <p className="invalid-feedback" style={{textAlign:"left"}}>{emailError}</p>}
        </div>

        
        <div className="form-group">
          <p className="form-input-headings">
            फोटो
            <span className="text-danger">*</span>
          </p>
          <input type="file" name="photo" />
        </div>

        <div className="form-group">
          <p className="form-input-headings">
            Registration fee
            <span className="text-danger">*</span>
          </p>
          <input
            type="text"
            name="amount"
            value={amount}
           
          // placeholder="स्वत"
          />
        </div>



        <div className="form-group">
          <button className="lab-btn my-2" type="submit" disabled={isDisabled}>
              {loading ? (
                <>
                  <span className="spinner-border spinner-border-sm me-3 text-white" role="status" aria-hidden="true"></span>
                  <span>Processing...</span>
                </>
              ) : (
                <span>Register and Pay</span>
              )}
            </button>
        </div>
      </form>

    </div>
  )
}

export default Shabhasad