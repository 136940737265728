


const GoogleMap = () => {
    return (
        <div className="map-area">
            <div className="maps">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3752.2102450435077!2d75.32597477440264!3d19.87334222645966!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bdb986fc948432b%3A0x9041e414e45b8fec!2sChhatrapati%20Shivaji%20Maharaj%20Statue%20Kranti%20Chowk%20Chhatrapati%20SAMBHAJINAGAR!5e0!3m2!1sen!2sin!4v1684227194412!5m2!1sen!2sin"></iframe>
            </div>
        </div>
    );
}

export default GoogleMap;
