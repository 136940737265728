import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import AchievementTwo from "../component/section/achievement-2";
import Blog from "../component/section/blog";
import Instructor from "../component/section/instructor";
import Skill from "../component/section/skill";
import Student from "../component/section/student";

const subTitle = "Stateसमन्वय समिती";

const names = [
    "मा अरुण बोर्डे (विरोधी पक्ष नेता म न पा छ .संभाजीनगर )",
    "मा सतीश पटेकर अध्यक्ष पॅंथर सेना",
    "मा राहुल प्रधान अध्यक्ष युवा पॅंथर संघटना नांदेड",
    "मा सचिन बनसोडे प्रदेश सचिव बी एस पी",
    "मा अँड दादाराव नांगरे",
    "मा प्रकाश इंगळे सम्यक विद्यार्थी आंदोलन मराठवाडा",
    "मा .राजेश घोडे (मानवी हक्क अभियान Dist. अध्यक्ष बीड )",
    "मा अखिल पठाण प्रसिद्ध व्याख्याते संभाजी ब्रिगेड",
    "मा सचिन निकम रिपब्लिकन विद्यार्थी सेना",
    "मा दीपक निकाळजे नगरसेवक उस्मानपुरा छ संभाजीनगर",
    "मा प्रशांत सातपुते सामाजिक कार्यकर्ते",
    "मा सय्यद सादेक आम आदमी पार्टी बीड",
    "मा नितीन गायकवाड स्वरतेज म्युझिक अकॅडमी",
    "मा राहुल शिंदे (मा पश्चिम आध्यक्ष रा . कॉ .छ संभाजीनगर )",
    "मा राहुल मकासरे सामाजिक कार्यकर्ते",
    "मा गुणरत्न सोनवणे पॅंथर्स रिपब्लिकन विद्यार्थी आघाडी",
    "मा बबन राठोड कृषी सहाय्यक संघटना महाराष्ट्र State",
    "मा अमित गायके एम एस ई बी कोकण विभाग",
]


const aboutList = [
    {
        imgUrl: 'assets/images/about/icon/01.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Skilled Instructors',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
    {
        imgUrl: 'assets/images/about/icon/02.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Get Certificate',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
    {
        imgUrl: 'assets/images/about/icon/03.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Online Classes',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
]


const RajyaSimti = () => {
    return (
        <Fragment>
            <Header />
            <PageHeader title={'Stateसमन्वय समिती'} curPage={'Stateसमन्वय समिती'} />
            <div className="about-section style-3 padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-12 col-sm-8">
                            <div className="about-right">
                                <div className="section-header">
                                    <span className="subtitle">{subTitle}</span>
                                    {/* <h2 className="title">{title}</h2> */}
                                    {names.map((name, index)=>{
                                        return (
                                            <p className="privacy-policy-text" key={index}>
                                        {name}    
                                    </p>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default RajyaSimti;
