import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import Header from "../component/layout/header";
import { useParams , useNavigate} from 'react-router-dom';
import Footer from '../component/layout/footer';
import ApiConfig from '../component/ApiConfig';
import LoadingOverlay from 'react-loading-overlay';
import Swal from 'sweetalert2';
import { useLocation } from "react-router";
const StudentPaymentDetail = () => {
  const location = useLocation();
  const { state } = location;
  const [userId, setUserId] = useState(state?.userId || null);
  const [amount, setAmount]=useState(state?.amount || null)
  const [sanId, setSanid]=useState(state?.sanId || null)
  const [sanCat, setSancat]=useState(state?.sanCat | null)
  const [sanPer, setSanper]=useState(state?.sanPer || null)
  const [name, setName]=useState(state?.name || null)
  const [mobileno, setMobileno]=useState(state?.mobileno || null)
  const [sanRef, setSanRef]=useState(state?.sanRef || null)
  const [aadhar, setaadhar]=useState(state?.aadhar || null)
  const [sec, setSec]=useState(state?.sec || null)
  const [group, setgroup]=useState(state?.group || null)

  const [email, setemail]=useState(state?.email || null)
  const [address, setaddress]=useState(state?.address || null)
  
  return (
    <Fragment>
    <Header />
    <div className="login-section padding-tb section-bg">
      <div className="container-fluid">
    <div className="account-wrapper" style={{marginTop:"5rem"}} >
     
          <h5 className="card-title text-center ">Payment Details For</h5>

          <h5 className="card-title text-center mt-2" >विभाग</h5>
          <h5 className="card-title text-center mt-2"  id="vibhag" >{sec}</h5>
          <h5 className="card-title text-center mt-2" >Group <span id="student-group">{group}</span></h5>
          <form  method="post" name="customerData"  action="/paymentpage/studentsccavRequestHandler.php">
            <table className="table tabled-sm table-stripped" >
              
              <tbody>
              
                <tr>
                  <td colSpan="2">Compulsory information</td>
                </tr>
                <tr>
                  <td>Amount:</td>
                  <td>
                    <input type="text" name="amount" value={amount} />
                  </td>
                </tr>
                             
               
               
                <tr>
                  <td>Name:</td>
                  <td>
                    <input type="text" name="billing_name" id='billing_name' value={name}  />
                  </td>
                </tr>
                <tr>
                  <td>Address:</td>
                  <td>
                    <input type="text" name="billing_address" id="billing_address" value={address} />
                  </td>
                </tr>
               
                <tr>
                  <td>Mobile no:</td>
                  <td>
                    <input type="text" name="billing_tel" id="billing_tel" value={mobileno}  />
                  </td>
                </tr>
                <tr>
                  <td>Aadhar Number:</td>
                  <td>
                    <input type="text"  id="aadharno"  value={aadhar} />
                  </td>
                </tr>
                <tr>
                  <td>Section</td>
                  <td>
                    <input type="text"  id="sec" value={sec}  />
                  </td>
                </tr>
            
                <tr>
                  <td> Email:</td>
                  <td>
                    <input type="text" name="billing_email" id="billing_email" value={email}  />
                  </td>
                </tr>
              
                
                
                
                
                    <input type="hidden" name="delivery_tel" value="" />
                    <input type="hidden" name="userId"  value={userId}  />
                    <input type="hidden" name="sanId" value={sanId} />
                    <input type="hidden" name="sanRef" value={sanRef} />
                    <input type="hidden" name="sanCat" value={sanCat} />
                    <input type="hidden" name="sanPer" value={sanPer} />
                <tr>
                  <td></td>
                  <td>
                    <input className='btn btn-danger' type="submit"  name="submit_form"/>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
    </div>
    </div>

  <Footer/>
  </Fragment>
  )
}

export default StudentPaymentDetail