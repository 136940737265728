import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import Archive from "../component/sidebar/archive";
import Author from "../component/sidebar/author";
import Comment from "../component/sidebar/comment";
import Instagram from "../component/sidebar/instagram";
import PopularPost from "../component/sidebar/popular-post";
import PostCategory from "../component/sidebar/post-category";
import Respond from "../component/sidebar/respond";
import Search from "../component/sidebar/search";
import Tags from "../component/sidebar/tags";

const socialList = [
  {
    link: "#",
    iconName: "icofont-facebook",
    className: "facebook",
  },
  {
    link: "#",
    iconName: "icofont-twitter",
    className: "twitter",
  },
  {
    link: "#",
    iconName: "icofont-linkedin",
    className: "linkedin",
  },
  {
    link: "#",
    iconName: "icofont-instagram",
    className: "instagram",
  },
  {
    link: "#",
    iconName: "icofont-pinterest",
    className: "pinterest",
  },
];

const BlogSingle = () => {
  return (
    <Fragment>
      <Header />
      <PageHeader
        title={"संविधान का अमृत महोत्सव : वर्ष २०२३ से २०२५ संविधान जागरूकता अभियान : घर-घर संविधान"}
        curPage={"Blog Detais"}
      />
      <div className="blog-section blog-single padding-tb section-bg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-12">
              <article>
                <div className="section-wrapper">
                  <div className="row row-cols-1 justify-content-center g-4">
                    <div className="col">
                      <div className="post-item style-2">
                        <div className="post-inner">
                          <div className="post-thumb">
                            <img
                              src="assets/images/blog/single/01.jpg"
                              alt="blog thumb rajibraj91"
                              className="w-100"
                            />
                          </div>
                          <div className="post-content">
                            <h2>
                              संविधान का अमृत महोत्सव : वर्ष २०२३ से २०२५
                              संविधान जागरूकता अभियान : घर-घर संविधान
                            </h2>
                            <div className="meta-post">
                              <ul className="lab-ul">
                                <li>
                                  <a href="#">
                                    <i className="icofont-calendar"></i>22
                                    Nov,2021
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="icofont-ui-user"></i>इ झेड
                                    खोब्रागडे
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <p>
                              हम भारत के लोगों ने २६ नवंबर १९४९ को संविधान सभा
                              में भारत के संविधान को खुद के प्रति अर्पित किया ।
                              २६ नवंबर २०२४ को इस ऐतिहासिक घटना के ७५ वर्ष पूरे
                              होने जा रहे है। हम अमृत महोत्सवी वर्ष कह सकते हैं।
                              आपके जानकारी के लिये बताना जरूरी समजते है की,
                              सरकारी स्तर पर संविधान अभियान की शुरुआत देश में
                              पहली बार २००५ में नागपुर (महाराष्ट्र) से हुई थी।
                              नागपुर जिले के सभी स्कूलों से प्रतिदिन संविधान की
                              प्रस्तावना पढ़ने की अनूठी पहल की गयी। जिला परिषद
                              के तत्कालीन सी ई ओ इ . झेड खोब्रागडे(आय.ए.एस.),
                              इन्होने अपने कार्याधिकार में इसकी शुरुआत की उसी
                              वर्ष २००५ में २६ नवंबर को नागपूर में संविधान रैली
                              आयोजित कर सर्वप्रथम संविधान दिवस मनाया गया। उनके
                              निरंतर प्रयासों को फलशृती है की ,महाराष्ट्र सरकार
                              ने २००८ से और केंद्र सरकार ने २०१५ से हर साल
                              संविधान दिवस मनाने का आदेश जारी किया। इसके पीछे का
                              उद्देश्य संविधान के मौलिक विचार जन-जन तक घर-घर तक
                              पहुंचाना और राष्ट्र निर्माण में योगदान देना है।
                              नागरिकों के दिल और दिमाग न्याय समानता, स्वतंत्रता
                              को सस्कृति से संस्कारित करने के लिये, देश की एकता
                              और अखंडता वृद्धिंगत करने के लिये संविधानको २००५
                              'संविधान ओळख' (संविधान जागृकता/संविधान परिचय /
                              संविधान जागृती/संविधान परिचिता के नाम से शुरू की
                              गई थी। २६ नवंबर २०२४ को संविधान दिवस ७५ वर्ष का हो
                              रहा है।
                            </p>

                            <p>
                              तारीख २६ जनवरी १९५० को देश मे संविधान पूरी तरह से
                              लागू हो गया। पूर्व स्वशासन का सपना पूरा हो गया।
                              देश एक गणStateबन गया। जनकल्याण के लिए संसदीय
                              लोकशाही का शासन-प्रशासन शुरू हुआ। शासन की संसदीय
                              लोकतांत्रिक प्रणाली शुरू हुई। भारत संप्रभु हो गया।
                              २६ जनवरी २०२५ को इस घटना की ७५वीं वर्षगाठ है। इसे
                              हम गणतंत्र दिन- प्रजातंत्र दिन का अमृत महोत्सवी
                              वर्ष कह सकते है। जरूरत है संविधान दिन और गणतंत्र
                              दिन यानी संविधान की अमृत महोत्सवी वर्ष २०२३ २०२५
                              को समझने और समझकर संविधान को घर - घर पर पहुंचानेका
                              हरसंभव प्रयास करने की हाल ही में, देश मे विभिन्न
                              कार्यक्रमों के आयोजन के साथ -साथ 'घर - घर तिरंगा'
                              अभियान के साथ स्वतंत्रता का अमृत महोत्सव (७५ वां
                              वर्ष) मनाया गया। इसी तरह संविधान का अमृत महोत्सव
                              वर्ष २०२३ से २०२५ संविधान दिवस से गणतंत्र दिवस तक,
                              सरकार देश भर के स्कूलों, कॉलेजों वविद्यालयों में
                              'घर घर संविधान का अभियान कार्यान्वित करे
                            </p>

                            <p>
                              इसके लिए हमने संविधान फाऊंडेशन की ओर से प्रधान जी
                              को एक ज्ञापन भेजा है। इसमें संविधान जनजागृती के
                              लिये १४ प्रकार के प्रस्ताव दिए गए हैं। संविधान की
                              प्रास्ताविका (Preamble) सभी को पढ़नी चाहिए।
                              प्रास्ताविका संविधान की आत्मा है, संविधान कर
                              लक्ष्य और उद्देश्य इसी में है। संविधान का यह प्रथम
                              पृष्ठ है। संविधान का परीचय इस प्रास्ताविका
                              (Preamble) से ही होता है. संविधान के शिल्पकार
                              निर्माता . डॉ .बी. आर. आंबेडकर कहते है, "भारतीय
                              संविधान की प्रास्ताविका सामाजिक दर्शन यानी
                              स्वतंत्रता समानता और बंधुत्व को जीवन के सिध्दांतो
                              के रूप में बताती है। इसलिए, हमें चाहिए हमारे
                              राजनीतिक लोकतंत्र को सामाजिक लोकतंत्र बनाएं।
                              संविधान की प्रास्ताविका (Preamble) क्या कहती है:
                            </p>

                            <p>
                              हम, भारत के लोग, भारत को एक Full प्रभूत्व
                              संपन्न, समाजवादी, धर्मनिरपेक्ष, लोकतंत्रात्मक
                              गणStateबनाने के लिए तथा उसके समस्त नागरिकों को,
                              सामाजिक, आर्थिक और राजनीतिक न्याय, विचार,
                              अभिव्यक्ति, विश्वास, धर्म और उपासना की स्वतंत्रता,
                              प्रतिष्ठा और अवसर की समानता प्राप्त करनेके लिए तथा
                              उन सब मे व्यक्ति की गरिमा और राष्ट्र की एकता और
                              अखंडता सुनिश्चित करनेवाली बंधुता बढाने के लिए
                              दृढसंकल्प होकर अपनी इस संविधान सभा में आज २६
                              नवंबर, १९४९ को एतद्वारा इस संविधान को अंगिकृत,
                              अधिनियमित और आत्मार्पित करते है. चूंकि संविधानको
                              प्रस्तावना में प्रत्येक शब्द एक मूल्य प्रस्ताव है,
                              इसलिए इन शब्दों के अर्थ को ठीक से समझने की
                              आवश्यकता है। उसके लिए, प्रास्ताविका को पढ़ना और
                              पढ़ाना होगा। क्योंकि यह हमारे देश का संविधान है।
                              सभी भारतीय जनता का है. जनता के लिए है. लोगों के
                              कल्याण के लिए है। भारत के संविधान ने देश का नाम
                              भारत India - (Stateोका संघ ) (अनुच्छेद १) दिया है।
                              नागरिकों को भारतीय होने की पहचान मिली है। भाग-३
                              में नागरिकों के मौलिक अधिकार शामिल है। यह मौलिक
                              अधिकार छ प्रकार के है: १) समानता का अधिकार
                              (अनुच्छेद १४ से १८) २) स्वतंत्रता का अधिकार
                              (अनुच्छेद १९ से २२) ३) शोषण के खिलाफ अधिकार
                              (अनुच्छेद २३ और २४): ४) धार्मिक स्वतंत्रता का
                              अधिकार (अनुच्छेद २५ से २८) ५) सांस्कृतिक और
                              शैक्षिक अधिकार (अनुच्छेद २९ और ३०) ६) संवैधानिक
                              उपचार का अधिकार (अनुच्छेद ३२) । संविधान द्वारा दिए
                              गए इन हक और अधिकारों के कारण, प्रत्येक नागरिक को
                              खुद को आगे बढ़ने और सम्मानजनक जीवन जीने का अवसर
                              मिला है। इसलिए मौलिक अधिकारों को समझने के लिए
                              संविधान को पढ़ना जरूरी है। संवैधानिक अधिकारों की
                              रक्षा करना भी स्वयं नागरिकों की जिम्मेदारी है। यह
                              सरकार और न्यायपालिका की भी जिम्मेदारी है। भारत के
                              नागरिकों के रूप में अपने अधिकारों के बारे में
                              अनभिज्ञ और उदासीन रहना हमारे लिए खतरनाक है। हमे
                              देश के निर्माण में भाग लेना चाहिए।
                            </p>
                            <p>
                              भाग- IV (अनुच्छेद ३६ से ५१) में संविधान Stateकी
                              नीतियों के लिए दिशा-निर्देश देता है। भाग ४ मे
                              सरकार के कर्तव्य और जिम्मेवारी प्रस्तुत है। सरकार
                              को सभी नीतियों को,कार्यक्रमों को और योजनाओं को
                              जनकल्याणकारी बनाना है। सामाजिक और आर्थिक न्यायको
                              प्रस्थापित करणा यह उद्देश है। जनकल्याण के लिये
                              संसद सरकारे अपना संविधानिक दायित्व का निर्वाह
                              इमानदारी से करेगी। उस उद्देश्य पूर्ती के लिये
                              जरूरत के हिसाब से कानून बनाने के लिए संविधान ने
                              निर्देश दिया है। भाग ३ के लिये उच्च और सर्वोच्च
                              न्यायालयो मे जा सकते है, मगर भाग ४ के लिये नहीं।
                              अनुच्छेद ३८, ३९, ४१.४५ और ४६ बहुत महत्वपूर्ण है।
                              सामाजिक और आर्थिक न्याय प्रदान करने वाले ये
                              निर्देश है। सामाजिक और आर्थिक लोकतंत्र की स्थापना
                              करके आम आदमी की बुनियादी जरूरतों को पूरा करना और
                              मूलभूत सुविधाएं उपलब्ध कराने की जिम्मेदारी सरकार
                              पर है। अनुच्छेद ४६ सामाजिक न्याय का व्हिजन और मिशन
                              है। Stateकमजोर वर्गोऔर विशेष रूप से अनुसूचित
                              जातियों और अनुसूचित जनजातियों के शैक्षिक और आर्थिक
                              हितों को विशेष देखभाल के साथ बढ़ावा देगा और उन्हें
                            </p>
                            <br></br>
                            <br></br>
                            <p>
                              सामाजिक अन्याय और सभी प्रकार के शोषण से बचाएगा।
                              जातिगत भेदभाव, विषमता और असमानता पर आधारित सामाजिक
                              व्यवस्था ने अधिकांश लोगों को मानव अधिकारों, गरिमा,
                              कल्याण, प्रगति और विकास से वंचित कर दिया था।
                              संविधान ने लोगों को जातिगत भेदभाव, विषमता, असमानता
                              मानवी दमनकारी परंपराओं से मुक्त किया और विशेष
                              प्रावधानों के माध्यम से सभी क्षेत्रों में उत्पीडित
                              वंचित वर्गौ के लिए अवसर के द्वार खोल दिए। इसलिए
                              कमजोर वर्ग, अनुसूचित जाति और जनजाति कुछ हद तक ही
                              क्यों ना हो, आगे आते नजर आ रहे हैं। ऐसा संविधान के
                              कारण हो रहा है संविधान जागृतता से नागरिक सशक्त
                              होगे। यह पूर्ण स्वशासन का सपना है जो देश को विकसित
                              और शक्तिशाली और आत्मनिर्भर बनने में मदद करेगा।
                              संविधान सिखना सिखाना घर-घर पहुंचाना जरूरी है। देश
                              हित के लिये यह अभियान है।
                            </p>
                            <p>
                              मौलिक अधिकारों के साथ नागरिकों के लिए कुछ
                              कर्तव्यों को संविधान में शामिल किया गया है (१९७६
                              के ४२ वे संशोधन द्वारा भाग ५१ क) संविधान द्वारा
                              प्रदत अधिकार और कर्तव्य एक ही सिक्के के दो पहलू
                              है। नागरिकों के कर्तव्य ११ प्रकार के है (१)
                              संविधान का पालन करना तथा उसके आदर्शों और संस्था,
                              राष्ट्रीय ध्वज और राष्ट्रगान का सम्मान करना (२)
                              हमारे राष्ट्रीय स्वतंत्रता संग्राम को प्रेरित करने
                              वाले कल के आदर्शों को विकसित करना और उनका पालन
                              करना (३) भारत की संप्रभुता, एकता और अखंडता को बनाए
                              रखना और उसकी रक्षा करना (४) आह्वान किए जाने पर
                              राष्ट्रीय सेवा करना और देश की रक्षा करना । (५)
                              धार्मिक, भाषाई क्षेत्रीय या वर्ग भेदों को पार कर
                              भारत के लोगों के बीच एकता और भाईचारे को बढ़ावा
                              देना, उन प्रथाओं को त्यागना जो महिलाओं की छवि को
                              खराब करती हैं। (६) हमारी समग्र सांस्कृतिक विरासत
                              को महत्व देना और संरक्षित करना। (७) जंगल, झीलों,
                              नदियों और वन्यजीवों सहित प्राकृतिक पर्यावरण की
                              रक्षा और सुधार करना तथा जीवित प्राणियों के प्रती
                              दयाभाव रखना। (८) वैज्ञानिक दृष्टिकोण, मानवतावाद,
                              संशोधन और सुधारवाद को बढ़ावा देना। (९) सार्वजनिक
                              संपति की रक्षा करना और हिंसा से दूर रहना (१०)
                              व्यक्तिगत और सामुदायिक कार्य के सभी क्षेत्रों में
                              उत्कृष्टता के लिए प्रयास करना ताकि राष्ट्र लगातार
                              उच्च स्तर की गतिविधि और उपलब्धि तक पहुंच सके (११)
                              ६ से १४ साल के बच्चों के माता-पिता या पालनकर्ता
                              अपने बच्चों को लागू होने वाले शैक्षिक अवसर प्रदान
                              करेंगे। इसका उद्देश्य नागरिकों को इन सभी कर्तव्यों
                              की पूर्ति के प्रति जागरूक करना और उन्हें राष्ट्र
                              की विकास प्रक्रिया में सक्रिय भागीदारी दिलाना है।
                              लेकिन कई नागरिक अज्ञानी और उदासीन है। आज तस्वीर
                              बहुत अलग है. संविधान के कार्यान्वयन की मुख्य
                              जिम्मेदारी विधायिका, कार्यपालिका और न्यायपालिका के
                              साथ-साथ मीडिया और नागरिकों की भी है। यदि संविधान
                              की संस्थाएं अपने कर्तव्यों का पालन सत्यनिष्ठा,
                              कर्तव्यनिष्ठा और संवेदनशीलता और स्वाभिमान के साथ
                              राष्ट्रहित के लिए करें तो ही देश प्रगति की राह पर
                              चल सकता है।
                            </p>
                            <p>
                              भ्रष्टाचार और शोषण में लिप्त व्यक्ति देशहित में
                              सेवा नहीं कर सकता। सभी संगठनों और नागरिकों को देश
                              के लोकतंत्र को समुद्ध करने के लिए हरसंभव प्रयास
                              करना चाहिए। इसी को ध्यान में रखते हुए हमने २०१२
                              में संविधान फाऊंडेशन की स्थापना की। इसके माध्यम से
                              हम संविधान जागरूकता की विभिन्न गतिविधियों को
                              क्रियान्वित कर रहे हैं। उदाहरण के तौर पर १)
                              संविधान परिषद, २) संविधान व्याख्यानमाला, ३)
                              संविधान साहित्य सम्मेलन, ४) संविधान दूत / संविधान
                              मित्र, ५) संविधान की पाठशाला, ६) संविधान पर
                              साहित्य का निर्माण, ७) संविधान सम्मान पुरस्कार, ८)
                              संविधान सभागृह का निर्माण, ९) संविधान चौक और वहा
                              प्रस्तावना का स्तंभ निर्माण करना, १०) पाठ्यक्रम
                              में संविधान विषय की अनिवार्यता इत्यादी । इन सभी
                              गतिविधियों के क्रियान्वयन के लिए सरकार के साथ समय
                              समयपर पत्राचार किया है। अभी भी कुछ प्रस्ताव बाकी
                              है। वर्ष २००५ में नागपुर से शुरू कि संविधान
                              जागरूकता की इस अभिनव पहल को लागू करने के लिए इ.
                              झेड. खोब्रागडे IAS और संविधान फाऊंडेशन सरकार से
                              निरंतर संपर्क में है। परिणाम स्वरूप हर साल २६
                              नवंबर को संविधान दिवस मनाने के २०१५ के निर्णय ने
                              'संविधान जागरूकता'की पहल को मान्यता मिली है। इसलिए
                              देशभर में स्कूलों, कॉलेजों, विश्वविद्यालयों,
                              कार्यालयों और विदेश स्थित भारतीय दूतावासों में हर
                              साल २६ नवंबर को 'संविधान दिवस' मनाया जाता है।
                              विभिन्न संगठन अपने-अपने तरीके से समाज में
                              संवैधानिक जागरूकता के विभिन्न कार्यक्रम आयोजित कर
                              रहे हैं। संविधान पर साहित्य निर्माण की प्रक्रिया
                              में तेजी आयी है। कार्यक्रमों में प्रास्ताविका को
                              पढ़ा जाता है। संविधान को उपहार के रूप में दिया जाता
                              है। प्रास्ताविक को स्मृतिचिन्ह के रूप में प्रदान
                              किया जाता है। 'संविधान जागरूकता' कार्यक्रमों को
                              लेकर आज हम जो तस्वीर देखते है, वह २००५ से पहले
                              नहीं थी। इसे 'संविधान जागरूकता' पहल की उपलब्धि कहा
                              जा सकता है। २००५ में एक आई.ए.एस. अधिकारी इ. झेड.
                              खोब्रागडे द्वारा शुरू किया गया प्रास्ताविका वाचन
                              और संविधान दिवस कार्यक्रम अब पूरे देश में आयोजित
                              किया जा रहा है। प्रास्ताविका संविधान की किताबसे
                              बाहर आकर दिवान खानेमे दिखने लगी है। वर्ष २०२१ मे
                              २६ नवंबर को सेंट्रल हॉल मे राष्ट्रपती महोदय ने
                              प्रास्ताविका का वाचन किया। वर्ष २०१५ मे, दो दिन का
                              संसद का विशेष सत्र आयोजित किया गया था। वर्ष २००५
                              पूर्व ऐसा कुछ होता नजर नहीं आया। महाराष्ट्र मे तो
                              शालेय किताबों के पहले पन्ने पर संविधान की
                              प्रास्ताविका २००७ से छापी जा रही है। हमने शुरू
                              किया संविधान जागरूकता (संविधान ओळख) उपक्रम की यह
                              उपलब्धी कही जा सकती है करोड़ो स्कूली, कॉलेजो के
                              बच्चे, लाखों शिक्षक-प्राध्यापक, लाखो
                              अधिकारी-कर्मचारी और करोड़ो नागरिक संविधान की
                              प्रास्ताविक पढते है, संविधान की विशेषताओको समझने
                              की कोशिष हो रही है। इसके बावजूद भी आज भी बहुसंख्य
                              लोग अभी भी संविधान के बारे में अनभिज्ञ, अज्ञानी और
                              उदासीन है। इसलिए घर-घर तक 'संविधान जागरूकता करने
                              का प्रयास करना आवश्यक है, वर्ष २०२३ से २०२५ तक
                              संविधान का अमृत महोत्सव पुरे देश में मनाने के
                              प्रयासों की आवश्यकता है। संविधान की स्वीकृति और
                              कार्यान्वयन आपको गरिमापूर्ण जीवन देता है। उसके लिए
                              संविधान निर्माण का इतिहास, अधिकारों और कर्तव्यों,
                              Stateकी नीतियों के मार्गदर्शक सिद्धांतों,
                              लोकतंत्र के स्तंभों के कार्यों और कर्तव्यों,
                              संविधान की विशेषताओं को मूल रुप में समझने के लिए
                              'गावं गावं घर-घर तक संविधान को पढ़ने का समजाने का
                              अभियान आवश्यक है। हम संविधान फाऊंडेशन के माध्यम से
                              इस दिशा में हरसंभव प्रयास करने जा रहे हैं। आपसे
                              नम्र निवेदन है की आप भी करें, संविधान मित्र बने
                              साथ दे। संविधान से ही मानव का कल्यान है, देश का
                              विकास है। देश की आन बान शान देश का संविधान है।
                              संविधान हमारा है, हमारे लिये है। सब भारतीय है।
                              सविधान का सम्मान देश का सम्मान है। कर्तव्य निभाये,
                              आत्मनिर्भर, आत्मसन्मान, प्रभुत्व संपन्न राष्ट्र
                              निर्माण में योगदान दे। संविधान दिन की हार्दिक
                              शुभकामनाएँ....।
                            </p>
                            <div className="tags-section">
                              {/* <ul className="tags lab-ul">
                                <li>
                                  <a href="#">Agency</a>
                                </li>
                                <li>
                                  <a href="#">Business</a>
                                </li>
                                <li>
                                  <a href="#">Personal</a>
                                </li>
                              </ul> */}
                              <ul className="lab-ul social-icons">
                                {socialList.map((val, i) => (
                                  <li key={i}>
                                    <a
                                      href={val.link}
                                      className={val.className}
                                    >
                                      <i className={val.iconName}></i>
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="navigations-part">
                        <div className="left">
                          <a href="#" className="prev">
                            <i className="icofont-double-left"></i>Previous
                            Article
                          </a>
                          <a href="#" className="title">
                            Evisculate Parallel Processes via Technica Sound
                            Models Authoritative
                          </a>
                        </div>
                        <div className="right">
                          <a href="#" className="prev">
                            Next Article<i className="icofont-double-right"></i>
                          </a>
                          <a href="#" className="title">
                            Qvisculate Parallel Processes via Technica Sound
                            Models Authoritative
                          </a>
                        </div>
                      </div>
                      <Author  />
                      {/* <Comment /> */}
                      {/* <Respond /> */}
                    </div>
                  </div>
                </div>
              </article>
            </div>
            <div className="col-lg-4 col-12">
              <aside>
                {/* <Search />
                <PostCategory /> */}
                <PopularPost />
                {/* <Archive />
                <Instagram />
                <Tags /> */}
              </aside>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default BlogSingle;
