import React, {useState, useEffect} from 'react'
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import "./styled.css"
import { Fragment } from "react";
import Swal from 'sweetalert2';
import axios from 'axios';
import { useParams , useNavigate} from 'react-router-dom';
import ApiConfig from '../component/ApiConfig';
import LoadingOverlay from 'react-loading-overlay';
export const SabhasadVerifyOtp = () => {
    const baseURL = ApiConfig();
    const [otpValue, setOtpValue] = useState('');
    const [timer, setTimer] = useState('0:59');
    const [isResendDisabled, setIsResendDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [userid, setUserid] = useState('');
    const [mobileno, setMobileNo] = useState('');
    const {uniquekey}=useParams();
    const navigate = useNavigate();
   
    useEffect(() => {
      const fetchUserInfo = async () => {
        setLoading(true)
        try {
          const response = await fetch(baseURL+'sabhasadtokenInfo', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              userkey: uniquekey,
            }),
          });
  
          if (response.ok) {
            const data = await response.json();
            setLoading(false)
            console.log(data.status);
            setMobileNo(data.mobileno);
            setUserid(data.userId)
          } 
          
          else {
            setLoading(false)
            navigate('/signup')
            
          }
        } catch (error) {
          setLoading(false)
          // Handle network errors
          navigate('/signup')
          console.log('Error:', error.message);
        }
      };
  
      fetchUserInfo();
    }, []);

    const OTPInput = () => {
      const inputs = document.querySelectorAll('#otp > *[id]');
  
      for (let i = 0; i < inputs.length; i++) {
        inputs[i].addEventListener('input', function(event) {
          inputs[i].value = event.target.value.replace(/[^0-9]/g, '');
  
          if (inputs[i].value !== '') {
            if (i !== inputs.length - 1) {
              inputs[i + 1].focus();
            } else {
              const otp = Array.from(inputs).map(input => input.value).join('');
              setOtpValue(otp); // Update the OTP value in state
            }
          }
        });
  
        inputs[i].addEventListener('keydown', function(event) {
          if (event.key === 'Backspace' && inputs[i].value === '') {
            if (i !== 0) {
              inputs[i - 1].focus();
            }
          }
        });
      }
    };
    const startTimer = () => {
        let timeLeft = 59;
        const interval = setInterval(() => {
          if (timeLeft > 0) {
            const formattedTime = `0:${timeLeft < 10 ? '0' : ''}${timeLeft}`;
            setTimer(formattedTime);
            timeLeft--;
          } else {
            setTimer('0:00');
            setIsResendDisabled(false); // Enable the "Resend OTP" button
            clearInterval(interval);
          }
        }, 1000);
      };
  
    useEffect(() => {
      OTPInput();
      startTimer();
    }, []);
  
    const handleSubmitOtp = () => {
      setLoading(true)
      const data = {
        userId:userid,
        otpno: otpValue
      };
      axios.post(baseURL+'verifySabhasadOtp', data)
      .then(response => {
        setLoading(false)
        if(response.data.status==='pverified'){
          const paymentToken=response.data.paymentkey;
          Swal.fire({
            icon: 'success',
            title: response.data.message,
            text: 'Click here to make payment',
            allowOutsideClick:false,
            showConfirmButton:true,
            confirmButtonText:'Proceed to Pay'  
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              navigate(`/sabhasad-payment-details/${paymentToken}`)
            } 
          })
        }
        else if(response.data.status==='success'){
          Swal.fire({
            icon: 'success',
            title: response.data.message,
            allowOutsideClick:false,
            showConfirmButton:true,
            confirmButtonText:'Back to home'  
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              navigate('/')
            } 
          })
        }
        else if(response.data.status==='failed'){
          Swal.fire({
            icon: 'error',
            title: response.data.message,
          })
        }
        else if(response.data.status==='verified'){
          const paymentToken=response.data.paymentkey;
          Swal.fire({
            icon: 'success',
            title: response.data.message,
            allowOutsideClick:false,
            showConfirmButton:true,
            confirmButtonText:'Proceed to Pay'  
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              navigate(`/sabhasad-payment-details/${paymentToken}`)
            } 
          })
        }else{
          Swal.fire({
            icon: 'error',
            title: response.data.message,
           
          })
        }
       
        // Handle the response data here
      })
      .catch(error => {
        console.error('Error:', error);
        // Handle errors here
        setLoading(false)
      });
      setIsResendDisabled(true);

    };
  
    const handleSendOtp = async () => {
      setLoading(true)
      try {
        setLoading(false)
        const apiUrl = baseURL+'sendOtpSabhasad';
        const requestBody = {
          userId: userid
        };
  
        const response = await axios.post(apiUrl, requestBody);
     
  
        // Handle the response data as needed
        console.log(response.data);
        if(response.data.status==='success'){
          Swal.fire({
            icon: 'success',
            title: response.data.message,
           
          })
        }
        else if(response.data.status==='verified'){
          Swal.fire({
            icon: 'success',
            title: response.data.message,
           
          })
        }
        else if(response.data.status==='failed'){
          Swal.fire({
            icon: 'error',
            title: response.data.message,
           
          })
        }
        else {
          Swal.fire({
            icon: 'error',
            title: response.data.message,
           
          })
        }
      } catch (error) {
        console.error(error);
        setLoading(false)
      }
      setIsResendDisabled(true);
      startTimer();
    };
  return (
    <Fragment>
    <Header />
    <LoadingOverlay active={loading} spinner text="Processing..........">
    <div className="container height-100 d-flex justify-content-center align-items-center">
        <div className="position-relative">
            <div className="card p-4 text-center">
                <h6>Please enter the one time password <br></br> to verify your account</h6>
                <div> <span>A code has been sent to</span> <small> {mobileno}</small> </div>
                <div id="otp" className="inputs d-flex flex-row justify-content-center mt-2"> <input className="m-2 text-center form-control rounded" type="text" id="first" maxLength="1" /> <input className="m-2 text-center form-control rounded" type="text" id="second" maxLength="1" /> <input className="m-2 text-center form-control rounded" type="text" id="third" maxLength="1" /> <input className="m-2 text-center form-control rounded" type="text" id="fourth" maxLength="1" /> <input className="m-2 text-center form-control rounded" type="text" id="fifth" maxLength="1" /> <input className="m-2 text-center form-control rounded" type="text" id="sixth" maxLength="1" /> </div>
                <div className="mt-4"> <button className="btn btn-danger px-4 validate" onClick={handleSubmitOtp}>Verify OTP</button> </div>
               
                <p className="text-danger mt-2">{timer}</p>
                <button className="btn btn-sm btn-outline-primary" onClick={handleSendOtp} disabled={isResendDisabled}>Resend OTP</button>

            </div>

        </div>
    </div>
    </LoadingOverlay>
    <Footer/>
</Fragment>
  )
}
export default SabhasadVerifyOtp