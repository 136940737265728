import React,{useState, useEffect} from 'react'
import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import {Modal } from 'react-bootstrap';
import ApiConfig from '../component/ApiConfig';
import LoadingOverlay from 'react-loading-overlay';
import ReactPlayer from 'react-player';
const VideoGallery = () => {
    const baseURL = ApiConfig();
    const [loading, setLoading] = useState(false);
    const [videoList, setVideoList]=useState([]);
    const [videoModal, setVideoModal]=useState(false);
    const [videoLink, setVideoLink]=useState("https://youtu.be/NjKl08Lb9nE");
    const [videoTitle, setVideoTitle]=useState("वीडियो प्लेयर");
    useEffect(() => {
        const fetchData = async () => {
          try {
            setLoading(true);
            const response = await fetch(baseURL + 'manavVideoList', {
              method: 'GET',
            });
      
            if (response.ok) {
              const data = await response.json();
              setVideoList(data);
              setLoading(false);
              console.log(data);
            } else {
              setLoading(false);
              console.log('Error:', response.status);
            }
          } catch (error) {
            setLoading(false);
            console.log('Error:', error.message);
          }
        };
      
        fetchData();
      }, []);
      const handleClick = (e) => {
        const videoId = (e.currentTarget.getAttribute('data-video'));
        const videoTitlenew = (e.currentTarget.getAttribute('data-title'));
        setVideoTitle(videoTitlenew)
        setVideoLink(videoId)
        setVideoModal(true);
        
       
      };
  return (
    <Fragment>
    <Header />
    <LoadingOverlay active={loading} spinner text="Loading.....">
    <PageHeader title={'Video Gallery'} curPage={'Videos'} />
    <div className="blog-section padding-tb section-bg">
                <div className="container">
                    <div className="section-wrapper">
                        <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 justify-content-center g-4">
                        {videoList.map((index, i) => {return(
                                <div className="col" key={i.id} data-title={index.title} data-video={index.video} onClick={handleClick}>
                                    <div className="post-item">
                                        <div className="post-inner">
                                            <div className="post-thumb">
                                                <img src={`${baseURL}${index.thumb}`} alt={`${index.thumb}`} style={{height:"250px", width:"100%"}} />
                                            </div>
                                            <div className="post-content">
                                                <h6>{index.title}</h6>
                                               
                                                {/* <p className="clamped-blog-description">{val.desc}</p> */}
                                            </div>
                                    
                                        </div>
                                    </div>
                                </div>
                            )})}
                        </div>
                       
                    </div>
                </div>
            </div>
            <Modal
  show={videoModal}
  onHide={() => setVideoModal(false)}
  size="lg"
  aria-labelledby="contained-modal-title-vcenter"
  centered
  scrollable
  className="custom-modal"
>
  <Modal.Header
    style={{
      color: "#f16126",
      display: "flex",
      justifyContent: "center",
      borderBottom: "none",
    }}
  >
    <Modal.Title
      className="modal-title"
      id="contained-modal-title-vcenter"
    >
      {videoTitle}
    </Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <div className="video-wrapper">
      <ReactPlayer
        url={videoLink}
        
        controls
      />
    </div>
  </Modal.Body>
</Modal>

    </LoadingOverlay>
    <Footer />
    </Fragment>
  )
}

export default VideoGallery