import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Fragment } from 'react';
import Header from "../component/layout/header";
import { useParams , useNavigate} from 'react-router-dom';
import Footer from '../component/layout/footer';
import ApiConfig from '../component/ApiConfig';
import LoadingOverlay from 'react-loading-overlay';
import Swal from 'sweetalert2';

const SabhasadPaymentDetails = () => {
    const [loading, setLoading] = useState(false);
    const [amount, setAmount]=useState('0.00');
    const [orderID, setOrderID] = useState('');
    const [userId, setUserId]=useState(0);
    
    const baseURL = ApiConfig();
    const {paymentToken}=useParams();
    const navigate = useNavigate();
    useEffect(() => {
      const d = new Date().getTime();
      document.getElementById("tid").value = d;
    }, []);
  
    // const handleSubmit = (event) => {
  
    //   event.preventDefault();
    //   const formData = new FormData(event.target);
  
    //   axios.post("https://sbcexam.com/payment/sabhasadccavRequestHandler.php", formData)
    //     .then(response => {
    //       // Handle the response
    //       console.log(response.data.payLink);
    //       window.location.href = response.data.payLink
        
         
    //     })
    //     .catch(error => {
    //       // Handle errors
    //       console.error("Error submitting form:", error);
    //     });
    // };
  
    useEffect(() => {
      const fetchUserInfo = async () => {
        setLoading(true)
        try {
          const response = await fetch(baseURL+'sabhasadInfo', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              reqToken: paymentToken,
            }),
          });
  
          if (response.ok) {
            const data = await response.json();
           
            console.log(data);
            document.getElementById("billing_name").value=data.name;
            document.getElementById("billing_state").value=data.state;
            document.getElementById("billing_email").value=data.email;
            document.getElementById("billing_tel").value=data.mobileno;
            document.getElementById("billing_address").value=data.address;
            document.getElementById("billing_city").value=data.dist;
            document.getElementById("billing_taluka").value=data.dist;
            document.getElementById("aadharno").value=data.aadhar;
           
            
            setUserId(data.id);
            setAmount(data.amount+'.00');
            setLoading(false)
            
            const randomID = Math.floor(Math.random() * 1000000000);
            const orderID = `${data.id}${randomID}`;
            setOrderID(orderID.toString());
   
            if(data.payment_status==='1'){
              Swal.fire({
                icon: 'success',
                title: 'या Coordinatorासाठी तुमचे पेमेंट आधीच झाले आहे',
                text: 'परत येण्यासाठी येथे क्लिक करा',
                allowOutsideClick:false,
                showConfirmButton:true,
                confirmButtonText:'Homepage'  
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  navigate(`/`)
                } 
              })
            }
            
           
          } 
          
          else {
            
            
          }
        } catch (error) {
          
          // Handle network errors
        
          console.log('Error:', error.message);
        }
      };
  
      fetchUserInfo();
    }, []);
  return (
    <Fragment>
    <Header />
    <LoadingOverlay active={loading} spinner text="Processing..........">
    <div className="login-section padding-tb section-bg">
      <div className="container-fluid">
    <div className="account-wrapper" style={{marginTop:"5rem"}} >
     
          <h5 className="card-title text-center ">Payment Details For</h5>
          <h5 className="card-title text-center mt-2" >सभासद Registration</h5>
          <form method="post" name="customerData"  action="/payment/index.php">
            <table className="table tabled-sm table-stripped" >
              
              <tbody>
              
                <tr>
                  <td colSpan="2">Compulsory information</td>
                </tr>
                <tr>
                  <td>TID:</td>
                  <td>
                    <input type="text" name="tid" id="tid" readOnly />
                  </td>
                </tr>
              
                    <input type="hidden" name="merchant_id" value="3806277" />
                
                <tr>
                  <td>Order Id:</td>
                  <td>
                    <input type="text" name="order_id" value={orderID} />
                  </td>
                </tr>
                <tr>
                  <td>Amount:</td>
                  <td>
                    <input type="text" name="amount" value={amount} />
                  </td>
                </tr>
                <tr>
                  <td>Currency:</td>
                  <td>
                    <input type="text" name="currency" value="INR" />
                  </td>
                </tr>
               
                    <input type="hidden" name="redirect_url" value="https://sbcexam.com/payment/sabhasadccavResponseHandler.php" />
                 
                
                    <input type="hidden" name="cancel_url" value="https://sbcexam.com/payment/sabhasadccavResponseHandler.php" />
                 
                <tr>
                  <td>Language:</td>
                  <td>
                    <input type="text" name="language" value="EN" />
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">Billing information:</td>
                </tr>
                <tr>
                  <td>Billing Name:</td>
                  <td>
                    <input type="text" name="billing_name" id='billing_name'  />
                  </td>
                </tr>
                <tr>
                  <td>Billing Address:</td>
                  <td>
                    <input type="text" name="billing_address" id="billing_address" />
                  </td>
                </tr>
                <tr>
                  <td>Billing Taluka:</td>
                  <td>
                    <input type="text"  id="billing_taluka"  />
                  </td>
                </tr>
                <tr>
                  <td>Billing City:</td>
                  <td>
                    <input type="text" name="billing_city" id="billing_city"  />
                  </td>
                </tr>
                <tr>
                  <td>Billing State:</td>
                  <td>
                    <input type="text" name="billing_state" id='billing_state' />
                  </td>
                </tr>
               
                    <input type="hidden" name="billing_zip" value="425001" />
                  
                <tr>
                  <td>Billing Country:</td>
                  <td>
                    <input type="text" name="billing_country" value="India" />
                  </td>
                </tr>
                <tr>
                  <td>Billing Tel:</td>
                  <td>
                    <input type="text" name="billing_tel" id="billing_tel"  />
                  </td>
                </tr>
                <tr>
                  <td>Aadhar Number:</td>
                  <td>
                    <input type="text"  id="aadharno"  />
                  </td>
                </tr>
            
                <tr>
                  <td>Billing Email:</td>
                  <td>
                    <input type="text" name="billing_email" id="billing_email"  />
                  </td>
                </tr>
              
                
                    <input type="hidden" name="delivery_name" value="" />
                  
               
                    <input type="hidden" name="delivery_address" value="" />
                  
              
                    <input type="hidden" name="delivery_city" value="" />
                
                
                    <input type="hidden" name="delivery_state" value="" />
                
               
                    <input type="hidden" name="delivery_zip" value="" />
                  
              
                    <input type="hidden" name="delivery_country" value="" />
                
                
                
                    <input type="hidden" name="delivery_tel" value="" />
                 
                
                    <input type="hidden" name="merchant_param1" id='merchant_param1' value={userId}  />
                
               
                    <input type="hidden" name="merchant_param2" value="" />
                  
               
                    <input type="hidden" name="merchant_param3" value="" />
            
               
                    <input type="hidden" name="merchant_param4" value="" />
                  
               
                    <input type="hidden" name="merchant_param5" value="" />
                  
               
                    <input type="hidden" name="promo_code" value="" />
                  
                
                    <input type="hidden" name="customer_identifier" value="" />
                  
               
                    <input type="hidden" name="integration_type" value="" />
                
                <tr>
                  <td></td>
                  <td>
                    <input className='btn btn-danger' type="submit" value="Pay Now" />
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
    </div>
    </div>
</LoadingOverlay>
  <Footer/>
  </Fragment>
  )
}

export default SabhasadPaymentDetails