import React, {useState} from "react";
import { MaharashtraDistricts } from "./districts";
import axios from 'axios';
import { useNavigate } from "react-router-dom";



export const JilhaForm = ({ paid, amount, hideReferral, title, category }) => {
  const [selectedDistrict, setSelectedDistrict] = useState(""); // State to track the selected district
  const [selectedTahasil, setSelectedTahasil] = useState(""); // State to track the selected taluka
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [mobileError, setMobileError] = useState(null);
  const [aadharError, setAadharError] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [ref, setRef]=useState('')
  const [refError, setRedError]= useState('')
  const [refmsg, setRefmsg]= useState('')
  const [refmsgcColor, setRefmsgColor]= useState('')
  const [showRefBtn, setShowRefbtn]=useState(true)
  const [refDisabled,setRefDisabled]=useState(false)


  const [panError, setPanError] = useState(null);
  const navigate = useNavigate();

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);

    if (password !== e.target.value) {
      setPasswordError('Password and confirm password do not match.');
    } else {
      setPasswordError('');
    }
  };
  const handleDistrictChange = (e) => {
    setSelectedDistrict(e.target.value);
    setSelectedTahasil(""); // Reset the selected taluka when district changes
  };

  const handleTahasilChange = (e) => {
    setSelectedTahasil(e.target.value);
  };

const handleMobileChange = (e) => {
  let mobileNumber = e.target.value;
  mobileNumber = mobileNumber.replace(/\D/g, ""); // Remove non-numeric characters

  if (isNaN(mobileNumber)) {
    // Block alphabetic characters
    e.preventDefault();
  }

  if (mobileNumber.length > 10) {
    mobileNumber = mobileNumber.slice(0, 10); // Truncate to 10 digits
  }

  if (mobileNumber.length < 10) {
    setMobileError("Mobile number must be at least 10 digits");
    setIsDisabled(true);
  } else {
    setMobileError(null);
    setIsDisabled(false)
  }

  // Update the input field value with the sanitized mobile number
  e.target.value = mobileNumber;
};



const handleAadharChange = (e) => {
  let aadharNumber = e.target.value;
  aadharNumber=aadharNumber.replace(/\D/g, "");
  if (isNaN(aadharNumber)) {
    // Block alphabetic characters
    e.preventDefault();
  }
    if (aadharNumber.length > 12) {
      aadharNumber = aadharNumber.slice(0, 12); // Truncate to 12 digits
  }

  if (aadharNumber.length < 12) {
    setIsDisabled(true)
    setAadharError("Aadhar number must be 12 digits");
  } else {
    setIsDisabled(false)
    setAadharError(null);
  }

  // Update the input field value with the sanitized mobile number
  e.target.value = aadharNumber;
};

const handleAcChanged = (e) => {
  let acNumber = e.target.value;
  acNumber=acNumber.replace(/\D/g, "");
  if (isNaN(acNumber)) {
    // Block alphabetic characters
    e.preventDefault();
  }
    if (acNumber.length > 20) {
      acNumber = acNumber.slice(0, 20); // Truncate to 12 digits
  }

 

  // Update the input field value with the sanitized mobile number
  e.target.value = acNumber;
};

const handlePanChange = (e) => {
  let panNumber = e.target.value;

  // Remove any non-alphanumeric characters from the input
  panNumber = panNumber.replace(/[^A-Za-z0-9]/g, '');

  if (panNumber.length > 10) {
    panNumber = panNumber.slice(0, 10); // Truncate to 10 characters
  }

  if (panNumber.length < 10 || !/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(panNumber)) {
    setPanError("PAN should be a ten-character alphanumeric number (e.g., ABCDE1234F)");
    setIsDisabled(true)
  } else {
    setPanError(null);
    setIsDisabled(false)
  }

  // Update the input field value with the sanitized PAN number
  e.target.value = panNumber;
};



const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setIsDisabled(true)
    // Create a new instance of FormData
    const formData = new FormData(e.target);
  
    try {
      // Make the API call using Axios
      const response = await axios.post('https://admin.sbcexam.com/sanyojak_register.php', formData);
  
      // Handle the response here (e.g., show success message)
      console.log(response.data);
      if(response.data.status===true){
        setLoading(false);
        setIsDisabled(false)
        
        const paymentToken= response.data.paymentkey;
    
           
        navigate(`/payment-details/${paymentToken}`)
        // open otp page using useNavigate
      }
      if(response.data.status===false){
        setLoading(false);
        setIsDisabled(false)
         if(response.data.validateTo==='email'){
          setEmailError(response.data.message);
         }
         else if(response.data.validateTo==='mobile'){
          setMobileError(response.data.message)
         }
         else if(response.data.validateTo==='aadhar'){
          setAadharError(response.data.message);
           }
           else if(response.data.validateTo==='pan'){
             setPanError(response.data.message);
             }
             else{
              alert(response.data.message)
             }
      }
    } catch (error) {
      setLoading(false);
      setIsDisabled(false)
      // Handle errors here (e.g., show error message)
      console.error(error);
    }
  };
  

  const validateRefferal =(e)=>{
    e.preventDefault()
   
    const data = {
      ref:ref,
      scat:"jilha-sanyojak"
    };
    axios.post('https://admin.sbcexam.com/referralVerify', data)
    .then(response => {
  
      // Handle the response data here
      console.log(response.data)
      if(response.data.status==='success'){
        setRedError("1px solid green")
        setRefmsg(response.data.message)
        setRefmsgColor("green")
        setShowRefbtn(false)
        setRefDisabled(true)
      }else{
        setRedError("1px solid red")
        setRefmsgColor("red")
        setShowRefbtn(true)
        setRefDisabled(false)
        setRefmsg(response.data.message)
      }

    })
    .catch(error => {
      console.error('Error:', error);
      // Handle errors here
    
    });
  
  }

  const removeRef =()=>{
    setShowRefbtn(true)
    setRefDisabled(false)
    setRef('')
    setRedError("")
    setRefmsgColor("")
    setRefmsg('')
  }
  
  return (

    <div className="account-wrapper" id="form-wrapper">
      {title && <h3 className="title">{title}</h3>}
      <form className="account-form needs-validation" onSubmit={handleSubmit}>
      <div className="form-group">
      

          <input
            type="hidden"
            name="sanyojak_category"
            value={category}
          // placeholder="Name"
          />
        </div>
        <div className="form-group">
          <p className="form-input-headings">
            Name
            <span className="text-danger">*</span>
          </p>

          <input
            type="text"
            name="name"
          // placeholder="Name"
          onKeyDown={(e) => {
            const keyCode = e.keyCode || e.which;
            const keyValue = String.fromCharCode(keyCode);
            const regex = /^[a-zA-Z\s]+$/; // Regular expression to match alphabetic characters and spaces

            // Allow backspace (keyCode 8), alphabetic characters, and spaces
            if (keyCode !== 8 && !regex.test(keyValue)) {
              e.preventDefault();
            }
          }}
          required
          />
        </div>
  
 
  <div className="form-group">
    <p className="form-input-headings">
      Aadhar No. <span className="text-danger">*</span>
    </p>
    <input
      type="tel"
      name="aadhar"
      required
      onChange={handleAadharChange}
      className={aadharError ? 'form-control is-invalid' : ''}
    />
    {aadharError && <p className="invalid-feedback" style={{ textAlign: "left" }}>{aadharError}</p>}
  </div>
        <div className="form-group">
          <p className="form-input-headings">
          Pan Number
            <span className="text-danger">*</span>
          </p>

          <input
            type="text"
            name="pan"
            required
            onChange={handlePanChange}
          // placeholder="पद(रा./जि./ता./गट)"
          className={panError ? 'form-control is-invalid' : ''}
          />
          {panError && <p className="invalid-feedback" style={{ textAlign: "left" }}>{panError}</p>}
        </div>
          <div className="form-group"style={{position:"relative"}}>
            <p className="form-input-headings">
              Mobile Number
              <span className="text-danger">*</span>
            </p>
            <input
              type="text"
              name="mobileno"
              required
              onChange={handleMobileChange}
              className={mobileError ? 'form-control is-invalid' : ''}
            placeholder="Enter Whatsapp Number"
            />
            <i className="icofont-brand-whatsapp"  style={{position:"absolute", fontSize:"25px", top:"36px",right:"10px"}}></i>
            {mobileError && <p className="invalid-feedback" style={{ textAlign: "left" }}>{mobileError}</p>}
          </div>
      <div className="form-group">
          <p className="form-input-headings">
             State
            <span className="text-danger"></span>
          </p>
          <input
            type="text"
            name="state"
            value="Maharashtra"
          // placeholder="Address"
          />
        </div>

        <div className="form-group">
          <p className="form-input-headings">
             Dist. Select
            <span className="text-danger">*</span>
          </p>
          <select name="dist" onChange={handleDistrictChange} required >
            {MaharashtraDistricts.map((option)=>{
              return(
                <option value={option.name}>{option.name}</option>
              )
            })}
          </select>
        </div>
        {selectedDistrict && (
          <div className="form-group">
            <p className="form-input-headings">
              TalukaSelect
              <span className="text-danger" >*</span>
            </p>
            <select name="taluka" value={selectedTahasil} onChange={handleTahasilChange}>
              <option value="">Select Taluka</option>
              {MaharashtraDistricts.map((option) =>
                option.name === selectedDistrict ? (
                  option.tahasil.map((tahasil) => (
                    <option key={tahasil} value={tahasil}>
                      {tahasil}
                    </option>
                  ))
                ) : null
              )}
            </select>
          </div>
        )}
        <div className="form-group">
          <p className="form-input-headings">
          Address :
            <span className="text-danger">*</span>
          </p>
          <input
            type="text"
            name="address"
            required
          // placeholder="IFSC Code"
          />
        </div>
        <div className="form-group">
          <p className="form-input-headings">
          Work area: 
            <span className="text-danger">*</span>
          </p>
          <input
            type="text"
            name="work_location"
            required
          // placeholder="मो.नंबर"
          />
        </div>
  


        <div className="form-group">
          <p className="form-input-headings">
            Bank Name
            <span className="text-danger">*</span>
          </p>
          <input
            type="text"
            name="bank_name"
            onKeyDown={(e) => {
              const keyCode = e.keyCode || e.which;
              const keyValue = String.fromCharCode(keyCode);
              const regex = /^[a-zA-Z\s]+$/; // Regular expression to match alphabetic characters and spaces

              // Allow backspace (keyCode 8), alphabetic characters, and spaces
              if (keyCode !== 8 && !regex.test(keyValue)) {
                e.preventDefault();
              }
            }}
            required
          // placeholder="Bank Name"
          />
        </div>
        <div className="form-group">
          <p className="form-input-headings">
          Account Holder Name
            <span className="text-danger">*</span>
          </p>
          <input
            type="text"
            name="name_on_bank"
            required
            onKeyDown={(e) => {
              const keyCode = e.keyCode || e.which;
              const keyValue = String.fromCharCode(keyCode);
              const regex = /^[a-zA-Z\s]+$/; // Regular expression to match alphabetic characters and spaces

              // Allow backspace (keyCode 8), alphabetic characters, and spaces
              if (keyCode !== 8 && !regex.test(keyValue)) {
                e.preventDefault();
              }
            }}
          // placeholder="Account Holder Name"
          />
        </div>
        <div className="form-group">
          <p className="form-input-headings">
          A / C No :-
            <span className="text-danger">*</span>
          </p>
          <input
            type="text"
            name="accountno"
            required
          // placeholder="Bank Name"
          onChange={handleAcChanged}
          />
        </div>
        <div className="form-group">
          <p className="form-input-headings">
            IFSC Code
            <span className="text-danger">*</span>
          </p>
          <input
            type="text"
            name="ifcse"
            required
          // placeholder="IFSC Code"
          />
        </div>
        <div className="form-group">
          <p className="form-input-headings">
          Branch Name :
            <span className="text-danger">*</span>
          </p>
          <input
            type="text"
            name="branch"
            required
          // placeholder="IFSC Code"
          />
        </div>
       
        <div className="form-group">
          <p className="form-input-headings">
            Email id <span className="text-danger">*</span>
          </p>
          <input
            type="email"
            name="email"
            required
            onChange={() =>setEmailError(null)}
            className={emailError ? 'form-control is-invalid' : ''}
            // placeholder="IFSC Code"
          />
          {emailError && <p className="invalid-feedback" style={{textAlign:"left"}}>{emailError}</p>}
        </div>

        

        {!hideReferral && (
          <div className="form-group" style={{position:"relative"}}>
            <p className="form-input-headings">
              Refer code
              <span className="text-danger">*</span>
            </p>
            <input
              type="text"
              name="refered_by"
              placeholder="State Coordinator चा Refer code टाका"
              value={ref}
              readOnly={refDisabled}
              style={{border:refError}}
              onChange={
              (e)=>setRef(e.target.value)
            }
            />
            {showRefBtn? (
              <button className="btn btn-success" type="button" onClick={validateRefferal} style={{ position: "absolute", width: "100px", top: "1px", right: "2px" }}>Verify</button>
            ) : (
              <button className="btn btn-danger" type="button" onClick={removeRef} style={{ position: "absolute", width: "100px", top: "1px", right: "2px" }}>Remove</button>
            )}
          
            {refError && <p  style={{textAlign:"left", fontSize:"0.85rem", color:refmsgcColor}}>{refmsg}</p>}
          </div>
        )}
        <div className="form-group">
          <p className="form-input-headings">
            Registration fee
            <span className="text-danger">*</span>
          </p>
          <input
            type="text"
            name="amount"
            value={amount}
            
          // placeholder="स्वत"
          />
        </div>
        <div className="form-group">
        <p className="form-input-headings">
        Password
          <span className="text-danger">*</span>
        </p>
        <input
          type="password"
          name="password"
          value={password}
          onChange={handlePasswordChange}
          className={passwordError ? 'form-control is-invalid' : 'is-valid'}
        />
      </div>

      <div className="form-group">
        <p className="form-input-headings">
        Confirm Password 
          <span className="text-danger">*</span>
        </p>
        <input
          type="password"
          name="confirmPassword"
          value={confirmPassword}
          className={passwordError ? 'form-control is-invalid' : 'is-valid'}
          onChange={handleConfirmPasswordChange}
        />
        {passwordError && <p className="invalid-feedback" style={{ textAlign: 'left' }}>{passwordError}</p>}
      </div>
        <div className="form-group">
          <button className="lab-btn my-2" type="submit" disabled={isDisabled}>
              {loading ? (
                <>
                  <span className="spinner-border spinner-border-sm me-3 text-white" role="status" aria-hidden="true"></span>
                  <span>Processing...</span>
                </>
              ) : (
                <span>Register and Pay</span>
              )}
            </button>
        </div>
      </form>

    </div>

  );
};
