
import { Link } from "react-router-dom";
import Img from "../../assets/images/logo/logos_123.png"


const btnText = "Read More";


const categoryList = [
    {
        imgUrl: 'assets/images/category/icon/01.jpg',
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'Computer Science',
        count: '24 Course',
    },
    {
        imgUrl: 'assets/images/category/icon/02.jpg',
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'Civil Engineering',
        count: '04 Course',
    },
    {
        imgUrl: 'assets/images/category/icon/03.jpg',
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'Business Analysis',
        count: '27 Course',
    },
    {
        imgUrl: 'assets/images/category/icon/04.jpg',
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'Data Science Analytics',
        count: '28 Course',
    },
    {
        imgUrl: 'assets/images/category/icon/05.jpg',
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'Learning Management',
        count: '78 Course',
    },
    {
        imgUrl: 'assets/images/category/icon/06.jpg',
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'Computer Engineering',
        count: '38 Course',
    },
]


const Category = () => {
    return (
        <div className="category-section mt-3">
            <div className="container">
                <div className="section-header text-center">
                    {/* <span className="subtitle">{subTitle}</span> */}
                    {/* <h2 className="title">{title}</h2> */}
                    <h2 className="title">STUDENT BANEGA CROREPATI  </h2>
                </div>
                <div className="section-wrapper">
                    {/* <div className="row g-2 justify-content-center row-cols-xl-6 row-cols-md-3 row-cols-sm-2 row-cols-1">
                        {categoryList.map((val, i) => (
                            <div className="col" key={i}>
                                <div className="category-item text-center">
                                    <div className="category-inner">
                                        <div className="category-thumb">
                                            <img src={`${val.imgUrl}`} alt={val.imgAlt} />
                                        </div>
                                        <div className="category-content">
                                            <Link to="/course"><h6>{val.title}</h6></Link>
                                            <span>{val.count}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        ))}
                    </div> */}
                    <div className="row g-2 justify-content-evenly align-items-center">
                        <div className="col-lg-4 col-md-8 d-flex justify-content-center">
                            <img src={Img} className="img-fluid" />
                        </div>
                        <div className="col-lg-6 col-md-8 about-us-description">
                        <p>Phule Shahu Charitable Welfare Foundation has launched a project to foster the intellectual development of its students. The aim is to enhance their intellectual abilities, provide them with knowledge of good things, help them choose to avoid bad things, and encourage them to work towards their own sustenance and talent. The project is called SBC Exam (Student Banega Crorepati).</p>

                        
                            <p>
                            Students who participate will receive a trophy and certificate. A donation of one hundred rupees is required to join the exam. To register for the exam, visit sbcexam.com or use the SBC Exam mobile application. Please inform all your friends and relatives to join the SBC Exam and make the most of their future.
                            </p>
                            <Link to="/about" className="lab-btn"><span>{btnText}</span></Link>
                        </div>
                        <h2>Upcoming Exam : 01/01/2025</h2>
                    </div>
                    <div className="text-center mt-5">
                        
                        {/* <a href="/assets/pdf/TakankharS.pdf" target="_blank" type="button" className="lab-btn ms-2"><span>संविधान सन्मान परीक्षा माहिती पुस्तिका</span></a> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Category;