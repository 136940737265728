import React,{useState, useEffect} from "react";
import { Fragment } from "react";
import axios from "axios";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
const Donation = () => {
    const [orderID, setOrderID] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const d = new Date().getTime();
        document.getElementById("tid").value = d;
        const randomID = Math.floor(Math.random() * 10000000000000);
        const orderID = `${randomID}`;
        setOrderID(orderID.toString());
      }, []);
      const handleSubmit = (event) => {

        event.preventDefault();
        setLoading(true);
        setIsDisabled(true)
        const formData = new FormData(event.target);
    
        axios.post("https://sbcexam.com/payment/donationRequestHandler.php", formData)
          .then(response => {
            // Handle the response
            console.log(response.data.payLink);
            window.location.href = response.data.payLink
            setLoading(false);
            setIsDisabled(false)
           
          })
          .catch(error => {
            setLoading(false);
            setIsDisabled(false)
            // Handle errors
            console.error("Error submitting form:", error);
          });
      };
  return (
    <Fragment>
      <Header />
      <PageHeader title={"DONATION"} curPage={"DONATION"} />
      <div className="contact-section padding-tb">
        <div className="container w-50">
          <div className="section-header text-center">
          <h2 className="title">DONATION FORM</h2>
          </div>
          <div className="section-wrapper">
            <form className="account-form " method="post" name="customerData"  action="/payment/donationRequestHandler.php">
            <input type="hidden" name="tid" id="tid" readOnly />
            <input type="hidden" name="merchant_id" value="3806277" />
            <input type="hidden" name="order_id" value={orderID} />
            <input type="hidden" name="currency" value="INR" />
            <input type="hidden" name="redirect_url" value="https://sbcexam.com/payment/donationResponseHandler.php" />
            <input type="hidden" name="cancel_url" value="https://sbcexam.com/payment/donationResponseHandler.php" />
            <input type="hidden" name="language" value="EN" />
            <div className="form-group">
                <input
                  type="text"
                  name="billing_name" 
                  placeholder="संपूर्ण नाव*"
                  required
                />
              </div>

              <div className="form-group">
                <input
                  type="text"
                  name="billing_tel"
                  placeholder="मोबाईल नंबर*"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="amount"
                  placeholder="₹  ENER DONATION AMOUNT  *"
                  required
                />
              </div>
              <div className="form-group">
              <input type="text" name="billing_email" id="billing_email" placeholder=" इ मेल: example@gmail.com" />
              </div>
              <div className="form-group">
              <input type="number" name="billing_zip" id="billing_email" placeholder=" पिन कोड" />
              </div>
              
              
            <input type="hidden" name="billing_country" value="India" />
            <input type="hidden" name="billing_state" id='billing_state' />
            <input type="hidden" name="billing_city" id="billing_city"  />
            <input type="hidden" name="delivery_name" value="" />
            <input type="hidden" name="delivery_address" value="" />
            <input type="hidden" name="delivery_city" value="" />
            <input type="hidden" name="delivery_state" value="" />
            <input type="hidden" name="delivery_zip" value="" />
            <input type="hidden" name="delivery_country" value="" />
            <input type="hidden" name="delivery_tel" value="" />
            <input type="hidden" name="merchant_param1" id='merchant_param1'  />
            <input type="hidden" name="merchant_param2" value="" />
            <input type="hidden" name="merchant_param3" value="" />
            <input type="hidden" name="merchant_param4" value="" />
            <input type="hidden" name="merchant_param5" value="" />
            <input type="hidden" name="promo_code" value="" />
            <input type="hidden" name="customer_identifier" value="" />
           <input type="hidden" name="integration_type" value="" />
              <div className="form-group w-100 text-center">
                <button className="lab-btn" type="submit" name="submit_form">
                
                  {loading ? (
                <>
                  <span className="spinner-border spinner-border-sm me-3 text-white" role="status" aria-hidden="true"></span>
                  <span>Processing...</span>
                </>
              ) : (
                <span>Donate</span>
              )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Donation;